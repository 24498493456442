const { default: mock } = require('@/@fake-db/mock')

/* eslint-disable global-require */
// data
const data = {
  dates: [
    {
      id: 2,
      searchType: 1,
      seriesData: [
        {
          name: 'People',
          type: 'line',
          label: {
            show: true,
          },
          data: [
            120, 132, 101, 134, 90, 230, 210, 124, 655, 444, 333, 222, 144, 678,
            33,
          ],
        },
        {
          name: 'Car',
          type: 'line',
          label: {
            show: true,
          },
          data: [
            220, 182, 191, 234, 290, 330, 310, 22, 345, 67, 43, 23, 445, 33, 55,
            44,
          ],
        },
        {
          name: 'Bike',
          type: 'line',
          label: {
            show: true,
          },
          data: [
            150, 232, 201, 154, 190, 330, 644, 322, 566, 335, 332, 123, 234,
            432, 410, 0,
          ],
        },
      ],
      legendData: [
        '08-09',
        '09-10',
        '10-11',
        '11-12',
        '13-14',
        '14-15',
        '15-16',
        '16-17',
        '17-18',
        '18-19',
        '19-20',
        '20-21',
        '21-22',
        '22-23',
        '23-00',
      ],
      parkingData: [
        {
          time: '08-09',
          peopleNumber: 159,
          peopleCumulativeTotal: 6,
          carNumber: 159,
          carCumulativeTotal: 6,
          bikeNumber: 159,
          bikeCumulativeTotal: 6,
        },
        {
          time: '09-10',
          peopleNumber: 159,
          peopleCumulativeTotal: 6,
          carNumber: 159,
          carCumulativeTotal: 6,
          bikeNumber: 159,
          bikeCumulativeTotal: 6,
        },
        {
          time: '10-11',
          peopleNumber: 159,
          peopleCumulativeTotal: 6,
          carNumber: 159,
          carCumulativeTotal: 6,
          bikeNumber: 159,
          bikeCumulativeTotal: 6,
        },
        {
          time: '11-12',
          peopleNumber: 159,
          peopleCumulativeTotal: 6,
          carNumber: 159,
          carCumulativeTotal: 6,
          bikeNumber: 159,
          bikeCumulativeTotal: 6,
        },
        {
          time: '12-13',
          peopleNumber: 159,
          peopleCumulativeTotal: 6,
          carNumber: 159,
          carCumulativeTotal: 6,
          bikeNumber: 159,
          bikeCumulativeTotal: 6,
        },
        {
          time: '13-14',
          peopleNumber: 159,
          peopleCumulativeTotal: 6,
          carNumber: 159,
          carCumulativeTotal: 6,
          bikeNumber: 159,
          bikeCumulativeTotal: 6,
        },
        {
          time: '14-15',
          peopleNumber: 159,
          peopleCumulativeTotal: 6,
          carNumber: 159,
          carCumulativeTotal: 6,
          bikeNumber: 159,
          bikeCumulativeTotal: 6,
        },
        {
          time: '15-16',
          peopleNumber: 159,
          peopleCumulativeTotal: 6,
          carNumber: 159,
          carCumulativeTotal: 6,
          bikeNumber: 159,
          bikeCumulativeTotal: 6,
        },
        {
          time: '16-17',
          peopleNumber: 159,
          peopleCumulativeTotal: 6,
          carNumber: 159,
          carCumulativeTotal: 6,
          bikeNumber: 159,
          bikeCumulativeTotal: 6,
        },
        {
          time: '17-18',
          peopleNumber: 159,
          peopleCumulativeTotal: 6,
          carNumber: 159,
          carCumulativeTotal: 6,
          bikeNumber: 159,
          bikeCumulativeTotal: 6,
        },
        {
          time: '18-19',
          peopleNumber: 159,
          peopleCumulativeTotal: 6,
          carNumber: 159,
          carCumulativeTotal: 6,
          bikeNumber: 159,
          bikeCumulativeTotal: 6,
        },
        {
          time: '19-20',
          peopleNumber: 159,
          peopleCumulativeTotal: 6,
          carNumber: 159,
          carCumulativeTotal: 6,
          bikeNumber: 159,
          bikeCumulativeTotal: 6,
        },
        {
          time: '20-21',
          peopleNumber: 159,
          peopleCumulativeTotal: 6,
          carNumber: 159,
          carCumulativeTotal: 6,
          bikeNumber: 159,
          bikeCumulativeTotal: 6,
        },
        {
          time: '21-22',
          peopleNumber: 159,
          peopleCumulativeTotal: 6,
          carNumber: 159,
          carCumulativeTotal: 6,
          bikeNumber: 159,
          bikeCumulativeTotal: 6,
        },
        {
          time: '22-23',
          peopleNumber: 159,
          peopleCumulativeTotal: 6,
          carNumber: 159,
          carCumulativeTotal: 6,
          bikeNumber: 159,
          bikeCumulativeTotal: 6,
        },
        {
          time: '23-00',
          peopleNumber: 159,
          peopleCumulativeTotal: 6,
          carNumber: 159,
          carCumulativeTotal: 6,
          bikeNumber: 159,
          bikeCumulativeTotal: 6,
        },
      ],
      parkingFooterData: [
        {
          time: 'Daily Total',
          peopleNumber: 159,
          peopleCumulativeTotal: 6,
          carNumber: 159,
          carCumulativeTotal: 6,
          bikeNumber: 159,
          bikeCumulativeTotal: 6,
        },
      ],
    },
    {
      id: 1,
      searchType: 2,
      parkingData: [
        {
          time: '01/Dec/2021(Wed)',
          peopleNumber: 159,
          peopleCumulativeTotal: 6,
          peopleLastYearSameDateNumber: 159,
          peopleLastYearSameWeekNumber: 6,
          carNumber: 159,
          carCumulativeTotal: 6,
          carLastYearSameDateNumber: 159,
          carLastYearSameWeekNumber: 6,
          bikeNumber: 159,
          bikeCumulativeTotal: 6,
          bikeLastYearSameDateNumber: 159,
          bikeLastYearSameWeekNumber: 6,
        },
        {
          time: '02/Dec/2021(Thu)',
          peopleNumber: 159,
          peopleCumulativeTotal: 6,
          peopleLastYearSameDateNumber: 159,
          peopleLastYearSameWeekNumber: 6,
          carNumber: 159,
          carCumulativeTotal: 6,
          carLastYearSameDateNumber: 159,
          carLastYearSameWeekNumber: 6,
          bikeNumber: 159,
          bikeCumulativeTotal: 6,
          bikeLastYearSameDateNumber: 159,
          bikeLastYearSameWeekNumber: 6,
        },
        {
          time: '03/Dec/2021(Fri)',
          peopleNumber: 159,
          peopleCumulativeTotal: 6,
          peopleLastYearSameDateNumber: 159,
          peopleLastYearSameWeekNumber: 6,
          carNumber: 159,
          carCumulativeTotal: 6,
          carLastYearSameDateNumber: 159,
          carLastYearSameWeekNumber: 6,
          bikeNumber: 159,
          bikeCumulativeTotal: 6,
          bikeLastYearSameDateNumber: 159,
          bikeLastYearSameWeekNumber: 6,
        },
        {
          time: '04/Dec/2021(Sat)',
          peopleNumber: 159,
          peopleCumulativeTotal: 6,
          peopleLastYearSameDateNumber: 159,
          peopleLastYearSameWeekNumber: 6,
          carNumber: 159,
          carCumulativeTotal: 6,
          carLastYearSameDateNumber: 159,
          carLastYearSameWeekNumber: 6,
          bikeNumber: 159,
          bikeCumulativeTotal: 6,
          bikeLastYearSameDateNumber: 159,
          bikeLastYearSameWeekNumber: 6,
        },
        {
          time: '05/Dec/2021(Sun)',
          peopleNumber: 159,
          peopleCumulativeTotal: 6,
          peopleLastYearSameDateNumber: 159,
          peopleLastYearSameWeekNumber: 6,
          carNumber: 159,
          carCumulativeTotal: 6,
          carLastYearSameDateNumber: 159,
          carLastYearSameWeekNumber: 6,
          bikeNumber: 159,
          bikeCumulativeTotal: 6,
          bikeLastYearSameDateNumber: 159,
          bikeLastYearSameWeekNumber: 6,
        },
        {
          time: '06/Dec/2021(Mon)',
          peopleNumber: 159,
          peopleCumulativeTotal: 6,
          peopleLastYearSameDateNumber: 159,
          peopleLastYearSameWeekNumber: 6,
          carNumber: 159,
          carCumulativeTotal: 6,
          carLastYearSameDateNumber: 159,
          carLastYearSameWeekNumber: 6,
          bikeNumber: 159,
          bikeCumulativeTotal: 6,
          bikeLastYearSameDateNumber: 159,
          bikeLastYearSameWeekNumber: 6,
        },
        {
          time: '07/Dec/2021(Tue)',
          peopleNumber: 159,
          peopleCumulativeTotal: 6,
          peopleLastYearSameDateNumber: 159,
          peopleLastYearSameWeekNumber: 6,
          carNumber: 159,
          carCumulativeTotal: 6,
          carLastYearSameDateNumber: 159,
          carLastYearSameWeekNumber: 6,
          bikeNumber: 159,
          bikeCumulativeTotal: 6,
          bikeLastYearSameDateNumber: 159,
          bikeLastYearSameWeekNumber: 6,
        },
        {
          time: '08/Dec/2021(Wed)',
          peopleNumber: 159,
          peopleCumulativeTotal: 6,
          peopleLastYearSameDateNumber: 159,
          peopleLastYearSameWeekNumber: 6,
          carNumber: 159,
          carCumulativeTotal: 6,
          carLastYearSameDateNumber: 159,
          carLastYearSameWeekNumber: 6,
          bikeNumber: 159,
          bikeCumulativeTotal: 6,
          bikeLastYearSameDateNumber: 159,
          bikeLastYearSameWeekNumber: 6,
        },
        {
          time: '09/Dec/2021(Thu)',
          peopleNumber: 159,
          peopleCumulativeTotal: 6,
          peopleLastYearSameDateNumber: 159,
          peopleLastYearSameWeekNumber: 6,
          carNumber: 159,
          carCumulativeTotal: 6,
          carLastYearSameDateNumber: 159,
          carLastYearSameWeekNumber: 6,
          bikeNumber: 159,
          bikeCumulativeTotal: 6,
          bikeLastYearSameDateNumber: 159,
          bikeLastYearSameWeekNumber: 6,
        },
        {
          time: '10/Dec/2021(Fri)',
          peopleNumber: 159,
          peopleCumulativeTotal: 6,
          peopleLastYearSameDateNumber: 159,
          peopleLastYearSameWeekNumber: 6,
          carNumber: 159,
          carCumulativeTotal: 6,
          carLastYearSameDateNumber: 159,
          carLastYearSameWeekNumber: 6,
          bikeNumber: 159,
          bikeCumulativeTotal: 6,
          bikeLastYearSameDateNumber: 159,
          bikeLastYearSameWeekNumber: 6,
        },
        {
          time: '11/Dec/2021(Sat)',
          peopleNumber: 159,
          peopleCumulativeTotal: 6,
          peopleLastYearSameDateNumber: 159,
          peopleLastYearSameWeekNumber: 6,
          carNumber: 159,
          carCumulativeTotal: 6,
          carLastYearSameDateNumber: 159,
          carLastYearSameWeekNumber: 6,
          bikeNumber: 159,
          bikeCumulativeTotal: 6,
          bikeLastYearSameDateNumber: 159,
          bikeLastYearSameWeekNumber: 6,
        },
        {
          time: '12/Dec/2021(Sun)',
          peopleNumber: 159,
          peopleCumulativeTotal: 6,
          peopleLastYearSameDateNumber: 159,
          peopleLastYearSameWeekNumber: 6,
          carNumber: 159,
          carCumulativeTotal: 6,
          carLastYearSameDateNumber: 159,
          carLastYearSameWeekNumber: 6,
          bikeNumber: 159,
          bikeCumulativeTotal: 6,
          bikeLastYearSameDateNumber: 159,
          bikeLastYearSameWeekNumber: 6,
        },
        {
          time: '13/Dec/2021(Mon)',
          peopleNumber: 159,
          peopleCumulativeTotal: 6,
          peopleLastYearSameDateNumber: 159,
          peopleLastYearSameWeekNumber: 6,
          carNumber: 159,
          carCumulativeTotal: 6,
          carLastYearSameDateNumber: 159,
          carLastYearSameWeekNumber: 6,
          bikeNumber: 159,
          bikeCumulativeTotal: 6,
          bikeLastYearSameDateNumber: 159,
          bikeLastYearSameWeekNumber: 6,
        },
        {
          time: '14/Dec/2021(Tue)',
          peopleNumber: 159,
          peopleCumulativeTotal: 6,
          peopleLastYearSameDateNumber: 159,
          peopleLastYearSameWeekNumber: 6,
          carNumber: 159,
          carCumulativeTotal: 6,
          carLastYearSameDateNumber: 159,
          carLastYearSameWeekNumber: 6,
          bikeNumber: 159,
          bikeCumulativeTotal: 6,
          bikeLastYearSameDateNumber: 159,
          bikeLastYearSameWeekNumber: 6,
        },
        {
          time: '15/Dec/2021(Wed)',
          peopleNumber: 159,
          peopleCumulativeTotal: 6,
          peopleLastYearSameDateNumber: 159,
          peopleLastYearSameWeekNumber: 6,
          carNumber: 159,
          carCumulativeTotal: 6,
          carLastYearSameDateNumber: 159,
          carLastYearSameWeekNumber: 6,
          bikeNumber: 159,
          bikeCumulativeTotal: 6,
          bikeLastYearSameDateNumber: 159,
          bikeLastYearSameWeekNumber: 6,
        },
        {
          time: '16/Dec/2021(Thu)',
          peopleNumber: 159,
          peopleCumulativeTotal: 6,
          peopleLastYearSameDateNumber: 159,
          peopleLastYearSameWeekNumber: 6,
          carNumber: 159,
          carCumulativeTotal: 6,
          carLastYearSameDateNumber: 159,
          carLastYearSameWeekNumber: 6,
          bikeNumber: 159,
          bikeCumulativeTotal: 6,
          bikeLastYearSameDateNumber: 159,
          bikeLastYearSameWeekNumber: 6,
        },
      ],
      parkingFooterData: [
        {
          time: 'Total',
          peopleNumber: 159,
          peopleCumulativeTotal: 6,
          peopleLastYearSameDateNumber: 159,
          peopleLastYearSameWeekNumber: 6,
          carNumber: 159,
          carCumulativeTotal: 6,
          carLastYearSameDateNumber: 159,
          carLastYearSameWeekNumber: 6,
          bikeNumber: 159,
          bikeCumulativeTotal: 6,
          bikeLastYearSameDateNumber: 159,
          bikeLastYearSameWeekNumber: 6,
        },
      ],
      holidayData: [
        '11/Nov/2021(Thu)',
      ],
    },
  ],
}

// ------------------------------------------------
// GET: Return Single data
// ------------------------------------------------
mock.onGet(/\/apps\/parking\/dates\/\d+/).reply(config => {
  // Get event id from URL
  let id = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  id = Number(id)

  const index = data.dates.findIndex(e => e.id === id)
  const dat = data.dates[index]
  Object.assign(dat, {
    taskDone: 1230,
    projectDone: 568,
    taxId: 'Tax-8894',
    language: 'English',
  })

  if (dat) return [200, dat]

  return [404]
})
