import { mdiAccountEdit,
  mdiCalendarOutline,
  mdiEmailOutline,
  mdiFileDocumentOutline,
  mdiFileOutline,
  mdiMessageTextOutline,
  mdiMapMarkerPath,
  mdiDoor,
  mdiToilet,
  mdiCarBrakeParking,
  mdiVectorCombine,

  mdiAccountSupervisor,
  mdiQuadcopter,
  mdiClipboardSearch,
  mdiPencilBoxMultiple,
  mdiExport,
  mdiImport,
  mdiStorefront,
  mdiStorePlus,
  mdiDownloadMultiple,
  mdiTrafficCone,
  mdiLocationEnter,
  mdiHome,

  mdiCogOutline,
  mdiSendOutline } from '@mdi/js'

export default function appAndPages() {
  const menuData = [
    // {
    //   subheader: 'APPS AND PAGES',
    //   resource: 'subheader',
    //   action: 'read',
    // },
    {
      title: 'Entrance',
      to: { name: 'apps-entrance' },
      icon: mdiDoor,
      resource: 'Entrance',
      action: 'read',
    },
    {
      title: 'Corridor',
      to: { name: 'apps-corridor', params: { id: 1 } },
      icon: mdiMapMarkerPath,
      resource: 'Corridor',
      action: 'read',
    },
    {
      title: 'Search',
      icon: mdiClipboardSearch,
      to: 'apps-search',
      resource: 'Search',
      action: 'read',
    },

    {
      title: 'Staying',
      icon: mdiAccountSupervisor,
      to: 'apps-stay',
      resource: 'Staying',
      action: 'read',
    },
    {
      title: 'Memo Box',
      icon: mdiPencilBoxMultiple,
      to: 'apps-memobox',
      resource: 'Memobox',
      action: 'read',
    },
    {
      title: 'SC Comparison',
      icon: mdiQuadcopter,
      to: 'apps-comparison',
      resource: 'Comparison',
      action: 'read',
    },
    {
      title: 'CSV Download',
      icon: mdiDownloadMultiple,
      to: 'apps-csvDownload',
      resource: 'CSVDownload',
      action: 'read',
    },
    {
      title: 'Combined 1F Map',
      to: { name: 'apps-combined' },
      icon: mdiVectorCombine,
      resource: 'Combined',
      action: 'read',
    },
    {
      title: 'PeopleCarBike',
      to: { name: 'apps-parking' },
      icon: mdiCarBrakeParking,
      resource: 'PeopleCarBike',
      action: 'read',
    },
    {
      title: 'Toilet',
      to: { name: 'apps-toilet' },
      icon: mdiToilet,
      resource: 'Toilet',
      action: 'read',
    },
    {
      title: 'ExportCsv',
      to: { name: 'apps-exportCsv' },
      icon: mdiExport,
      resource: 'ExportCsv',
      action: 'read',
    },
    {
      title: 'ImportCsv',
      to: { name: 'apps-importCsv' },
      icon: mdiImport,
      resource: 'ImportCsv',
      action: 'read',
    },
    {
      title: 'Event',
      icon: mdiStorefront,
      to: 'apps-event',
      resource: 'Event',
      action: 'read',
    },
    {
      title: 'SetEvent',
      icon: mdiStorePlus,
      to: 'apps-setEvent',
      resource: 'SetEvent',
      action: 'read',
    },
    {
      title: 'Traffic',
      icon: mdiTrafficCone,
      to: 'apps-traffic',
      resource: 'Traffic',
      action: 'read',
    },

    {
      title: 'Option',
      icon: mdiCogOutline,
      to: 'apps-option',
      resource: 'Option',
      action: 'read',
    },

    // {
    //   title: 'GifPlayer',
    //   icon: mdiStorePlus,
    //   to: 'apps-gifPlayer',
    //   resource: 'GifPlayer',
    //   action: 'read',
    // },
    {
      title: 'User',
      icon: mdiAccountEdit,
      to: { name: 'apps-user' },
      resource: 'User',
      action: 'read',
    },
    {
      title: 'SendReport',
      icon: mdiSendOutline,
      to: { name: 'apps-sendReport' },
      resource: 'SendReport',
      action: 'read',
    },
    {
      title: 'ホーム',
      icon: mdiHome,
      to: { name: 'apps-home' },
      resource: 'Home',
      action: 'read',
    },
    {
      title: '入店/寄り付き',
      icon: mdiLocationEnter,
      to: { name: 'apps-enterShop' },
      resource: 'EnterShop',
      action: 'read',
    },
    {
      title: 'CSV出力',
      icon: mdiDownloadMultiple,
      to: 'apps-domesticCsvDownload',
      resource: 'DomesticCSVDownload',
      action: 'read',
    },
    {
      title: 'Calendar',
      icon: mdiCalendarOutline,
      to: 'apps-calendar',
    },
    {
      title: 'Chat',
      icon: mdiMessageTextOutline,
      to: 'apps-chat',
    },
    {
      title: 'Email',
      icon: mdiEmailOutline,
      to: 'apps-email',
    },
    {
      title: 'Invoice',
      icon: mdiFileDocumentOutline,
      children: [
        {
          title: 'List',
          to: 'apps-invoice-list',
        },
        {
          title: 'Preview',
          to: { name: 'apps-invoice-preview', params: { id: 4987 } },
        },
        {
          title: 'Edit',
          to: { name: 'apps-invoice-edit', params: { id: 4987 } },
        },
        {
          title: 'Add',
          to: { name: 'apps-invoice-add' },
        },
      ],
    },
    {
      title: 'Pages',
      icon: mdiFileOutline,
      children: [
        {
          title: 'Authentication',
          children: [
            {
              title: 'Login V1',
              to: 'auth-login-v1',
              target: '_blank',
            },
            {
              title: 'Login V2',
              to: 'auth-login-v2',
              target: '_blank',
            },
            {
              title: 'Register V1',
              to: 'auth-register-v1',
              target: '_blank',
            },
            {
              title: 'Register V2',
              to: 'auth-register-v2',
              target: '_blank',
            },
            {
              title: 'Forgot Password V1',
              to: 'auth-forgot-password-v1',
              target: '_blank',
            },
            {
              title: 'Forgot Password V2',
              to: 'auth-forgot-password-v2',
              target: '_blank',
            },
            {
              title: 'Reset Password V1',
              to: 'auth-reset-password-v1',
              target: '_blank',
            },
            {
              title: 'Reset Password V2',
              to: 'auth-reset-password-v2',
              target: '_blank',
            },
          ],
        },
        {
          title: 'Miscellaneous',
          children: [
            {
              title: 'Under Maintenance',
              to: 'misc-under-maintenance',
              target: '_blank',
            },
            {
              title: 'Error',
              to: 'misc-error',
              target: '_blank',
            },
            {
              title: 'Coming Soon',
              to: 'misc-coming-soon',
              target: '_blank',
            },
            {
              title: 'Not Authorized',
              to: 'misc-not-authorized',
              target: '_blank',
            },
          ],
        },
        {
          title: 'Knowledge Base',
          to: 'page-knowledge-base',
        },
        {
          title: 'Account Settings',
          to: 'page-account-settings',
        },
        {
          title: 'Pricing',
          to: 'page-pricing',
        },
        {
          title: 'FAQ',
          to: 'page-faq',
        },
      ],
    },
  ]

  return {
    menuData,
  }
}
