const { default: mock } = require('@/@fake-db/mock')

/* eslint-disable global-require */
// data
const data = {
  dates: [
    {
      id: 2,
      searchType: 1,
      image_src: '/images/mollImage/16/Combinedbk.png',
      image_witdh: '974px',
      image_height: '764px',
      entranceData: [
        {
          id: 'e4429',
          positionX: '7.3%',
          positionY: '74.9%',
          count: '12,987',
          title: 'Ent 01',
          titleBgColor: '#ff99fd',
          borderColor: '#ffccfe',
          indicatorDirection: 'down',

        },
        {
          id: 'e4430',
          positionX: '22%',
          positionY: '80%',
          count: '123,987',
          title: 'Ent 10',
          titleBgColor: '#ff99fd',
          borderColor: '#ffccfe',
          indicatorDirection: 'up',
        },
      ],
      arrowData: [
        {
          id: 'a4454',
          rotate: '0deg',
          positionX: '26.6%',
          positionY: '90%',
          color: '#E53935',
        },
        {
          id: 'a4455',
          rotate: '180deg',
          positionX: '30%',
          positionY: '87.15%',
          color: '#E53935',
        },

        {
          id: 'a4456',
          rotate: '0deg',
          positionX: '46.6%',
          positionY: '87.15%',
          color: '#4CAF50',
        },
        {
          id: 'a4457',
          rotate: '180deg',
          positionX: '50%',
          positionY: '87.15%',
          color: '#4CAF50',
        },
      ],
      blockData: [
        {
          id: 'b4454',
          positionX: '18.3%',
          positionY: '66.5%',
          title: 'Block A',
          blockInfo: [
            {
              direction: 'L',
              count: '1,212,987',
            },
            {
              direction: 'R',
              count: '2,212,987',
            },
          ],
          titleBgColor: '#ff99fd',
          borderColor: '#ffccfe',
        },
        {
          id: 'b4456',
          positionX: '49%',
          positionY: '70.5%',
          title: 'Block B',
          blockInfo: [
            {
              direction: 'L',
              count: '2,987',
            },
            {
              direction: 'R',
              count: '2,987',
            },
          ],
        },
      ],
    },
    {
      id: 1,
      searchType: 2,
      image_src: '/images/mollImage/16/Combinedbk.png',
      image_witdh: '974px',
      image_height: '764px',
      entranceData: [
        {
          id: 'e4429',
          positionX: '7.3%',
          positionY: '4.9%',
          count: '12,987',
          title: 'Ent 01',
          titleBgColor: '#ff99fd',
          borderColor: '#ffccfe',
          indicatorDirection: 'down',
        },
        {
          id: 'e4430',
          positionX: '22%',
          positionY: '5%',
          count: '123,987',
          title: 'Ent 02',
          titleBgColor: '#ff99fd',
          borderColor: '#ffccfe',
          indicatorDirection: 'down',
        },
      ],
    },
  ],
}

// ------------------------------------------------
// GET: Return Single data
// ------------------------------------------------
mock.onGet(/\/apps\/combined\/dates\/\d+/).reply(() => {
  // Get event id from URL
  // let id = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  const id = Number(2)

  const index = data.dates.findIndex(e => e.id === id)
  const dat = data.dates[index]
  Object.assign(dat, {
    taskDone: 1230,
    projectDone: 568,
    taxId: 'Tax-8894',
    language: 'English',
  })

  if (dat) return [200, dat]

  return [404]
})
