// axios
import axios from 'axios'
import Vue from 'vue'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: 'https://i-mcs2.com/mcs-server/',

  // baseURL: 'http://116.58.175.84:58080/mcs-server',

  // baseURL: process.env.NODE_ENV === 'production' ? 'http://116.58.175.84:58080/mcs-server' : 'http://localhost:8011/mcs-server',

  timeout: 120000,
  headers: {
    'Access-Control-Allow-Methods': 'POST',
    'content-type': 'application/json',
  },
})

axiosIns.interceptors.request.use(
  config => {
    const accessToken = sessionStorage.getItem('accessToken')

    // eslint-disable-next-line no-param-reassign
    if (accessToken) config.headers.Authorization = `${accessToken}`

    return config
  },
  error => Promise.reject(error),
)

Vue.prototype.$http = axiosIns

export default axiosIns
