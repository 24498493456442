<template>
  <v-menu
    offset-y
    left
    nudge-bottom="14"
    min-width="230"
    content-class="user-profile-menu-content"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        bottom
        color="success"
        overlap
        offset-x="12"
        offset-y="12"
        class="ms-4"
        dot
      >
        <v-avatar
          size="40px"
          v-bind="attrs"
          color="primary"
          class="v-avatar-light-bg primary--text"
          v-on="on"
        >
          <v-img
            v-if="userData.avatar"
            :src="userData.avatar"
          ></v-img>
          <v-icon
            v-else
            color="primary"
            size="28"
          >
            {{ icons.mdiAccountOutline }}
          </v-icon>
        </v-avatar>
      </v-badge>
    </template>
    <v-list>
      <div class="pb-3 pt-2">
        <v-badge
          bottom
          color="success"
          overlap
          offset-x="12"
          offset-y="12"
          class="ms-4"
          dot
        >
          <v-avatar
            size="40px"
            color="primary"
            class="v-avatar-light-bg primary--text"
          >
            <v-img
              v-if="userData.avatar"
              src="@/assets/images/avatars/1.png"
            ></v-img>
            <v-icon
              v-else
              color="primary"
              size="28"
            >
              {{ icons.mdiAccountOutline }}
            </v-icon>
          </v-avatar>
        </v-badge>
        <div
          class="d-inline-flex flex-column justify-center ms-3"
          style="vertical-align:middle"
        >
          <span class="text--primary font-weight-semibold mb-n1">
            {{ userData.fullName || userData.userName }}
          </span>
          <small class="text--disabled text-capitalize">{{ userData.role }}</small>
        </div>
      </div>

      <v-divider></v-divider>

      <!-- Logout -->
      <v-list-item @click="logoutUser">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiLogoutVariant }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mdiLogoutVariant } from '@mdi/js'
import { useRouter } from '@core/utils'
import { initialAbility } from '@/plugins/acl/config'
import { getCurrentInstance } from '@vue/composition-api'
import { constant } from '@core/utils/constant'
import crypto from 'crypto-js'

export default {
  setup() {
    const vm = getCurrentInstance().proxy
    const { router } = useRouter()
    const ecryptedData = sessionStorage.getItem('data')

    // 復号する
    const decrypted = crypto.AES.decrypt(ecryptedData, constant.secret)
    const userData = JSON.parse(decrypted.toString(crypto.enc.Utf8))

    const logoutUser = () => {
      let n = sessionStorage.length
      while (n > 0) {
        n -= 1
        const key = sessionStorage.key(n)
        sessionStorage.removeItem(key)
      }

      // Reset ability
      vm.$ability.update(initialAbility)

      console.log(router.currentRoute.path)

      // Redirect to login page
      if (router.currentRoute.path.indexOf('/home') >= 0 || router.currentRoute.path.indexOf('/enterShop') >= 0 || router.currentRoute.path.indexOf('/domestic') >= 0) {
        router.push({ name: 'auth-login-jp' })
      } else {
        router.push({ name: 'auth-login' })
      }
    }

    return {
      logoutUser,
      userData,

      icons: {
        mdiLogoutVariant,
      },
    }
  },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
