const { default: mock } = require('@/@fake-db/mock')

/* eslint-disable global-require */
// data
const data = {
  dates: [
    {
      id: 1,
      searchType: 1,
      text: '',
      comparisonData: [
        {
          time: '08-09',
          tanPhu: '',
          binhDuong: 308,
          longBien: 361,
          binhTan: 339,
          haDong: 219,
          leChan: 299,
        },
        {
          time: '09-10',
          tanPhu: '',
          binhDuong: 612,
          longBien: 574,
          binhTan: 569,
          haDong: 297,
          leChan: 546,
        },
        {
          time: '10-11',
          tanPhu: '',
          binhDuong: 774,
          longBien: 923,
          binhTan: 837,
          haDong: 854,
          leChan: 849,
        },
        {
          time: '11-12',
          tanPhu: '',
          binhDuong: 1009,
          longBien: 1031,
          binhTan: 882,
          haDong: 985,
          leChan: 926,
        },
        {
          time: '12-13',
          tanPhu: '',
          binhDuong: 850,
          longBien: 1107,
          binhTan: 923,
          haDong: 1007,
          leChan: 692,
        },
        {
          time: '13-14',
          tanPhu: '',
          binhDuong: 750,
          longBien: 1126,
          binhTan: 830,
          haDong: 857,
          leChan: 625,
        },
        {
          time: '14-15',
          tanPhu: '',
          binhDuong: 753,
          longBien: 1218,
          binhTan: 913,
          haDong: 1087,
          leChan: 953,
        },
        {
          time: '15-16',
          tanPhu: '',
          binhDuong: 814,
          longBien: 1327,
          binhTan: 932,
          haDong: 1081,
          leChan: 1343,
        },
        {
          time: '16-17',
          tanPhu: '',
          binhDuong: 943,
          longBien: 1398,
          binhTan: 1047,
          haDong: 1199,
          leChan: 1233,
        },
        {
          time: '17-18',
          tanPhu: '',
          binhDuong: 1453,
          longBien: 1653,
          binhTan: 1298,
          haDong: 1340,
          leChan: 1070,
        },
        {
          time: '18-19',
          tanPhu: '',
          binhDuong: 1899,
          longBien: 1646,
          binhTan: 2008,
          haDong: 1297,
          leChan: 1271,
        },
        {
          time: '19-20',
          tanPhu: '',
          binhDuong: 2041,
          longBien: 1935,
          binhTan: 2729,
          haDong: 1719,
          leChan: 1659,
        },
        {
          time: '20-21',
          tanPhu: '',
          binhDuong: 981,
          longBien: 1633,
          binhTan: 1428,
          haDong: 1535,
          leChan: 1706,
        },
        {
          time: '21-22',
          tanPhu: '',
          binhDuong: 378,
          longBien: 694,
          binhTan: 477,
          haDong: 711,
          leChan: 632,
        },
        {
          time: '22-23',
          tanPhu: '',
          binhDuong: 43,
          longBien: 93,
          binhTan: 96,
          haDong: 62,
          leChan: 169,
        },
        {
          time: '23-00',
          tanPhu: '',
          binhDuong: 4,
          longBien: 10,
          binhTan: 14,
          haDong: 29,
          leChan: 8,
        },
      ],
      seriesData: [
        13000, 17000, 15500, 14000, 13500,
      ],
      legendData: [
        'Binh Duong',
        'Long Bien',
        'Binh Tan',
        'Ha Dong',
        'Le Chan',
      ],
    },
    {
      id: 2,
      searchType: 2,
      text: '',
      comparisonData: [
        {
          time: '01/Dec/2021(Wed)',
          tanPhu: '',
          binhDuong: 308,
          longBien: 361,
          binhTan: 339,
          haDong: 219,
          leChan: 299,
        },
        {
          time: '02/Dec/2021(Thu)',
          tanPhu: '',
          binhDuong: 612,
          longBien: 574,
          binhTan: 569,
          haDong: 297,
          leChan: 546,
        },
        {
          time: '03/Dec/2021(Fri)',
          tanPhu: '',
          binhDuong: 774,
          longBien: 923,
          binhTan: 837,
          haDong: 854,
          leChan: 849,
        },
        {
          time: '04/Dec/2021(Sat)',
          tanPhu: '',
          binhDuong: 1009,
          longBien: 1031,
          binhTan: 882,
          haDong: 985,
          leChan: 926,
        },
        {
          time: '05/Dec/2021(Sun)',
          tanPhu: '',
          binhDuong: 850,
          longBien: 1107,
          binhTan: 923,
          haDong: 1007,
          leChan: 692,
        },
        {
          time: '06/Dec/2021(Mon)',
          tanPhu: '',
          binhDuong: 750,
          longBien: 1126,
          binhTan: 830,
          haDong: 857,
          leChan: 625,
        },
        {
          time: '07/Dec/2021(Tue)',
          tanPhu: '',
          binhDuong: 753,
          longBien: 1218,
          binhTan: 913,
          haDong: 1087,
          leChan: 953,
        },
        {
          time: '08/Dec/2021(Wed)',
          tanPhu: '',
          binhDuong: 814,
          longBien: 1327,
          binhTan: 932,
          haDong: 1081,
          leChan: 1343,
        },
        {
          time: '09/Dec/2021(Thu)',
          tanPhu: '',
          binhDuong: 943,
          longBien: 1398,
          binhTan: 1047,
          haDong: 1199,
          leChan: 1233,
        },
        {
          time: '10/Dec/2021(Fri)',
          tanPhu: '',
          binhDuong: 1453,
          longBien: 1653,
          binhTan: 1298,
          haDong: 1340,
          leChan: 1070,
        },
        {
          time: '11/Dec/2021(Sat)',
          tanPhu: '',
          binhDuong: 1899,
          longBien: 1646,
          binhTan: 2008,
          haDong: 1297,
          leChan: 1271,
        },
        {
          time: '12/Dec/2021(Sun)',
          tanPhu: '',
          binhDuong: 2041,
          longBien: 1935,
          binhTan: 2729,
          haDong: 1719,
          leChan: 1659,
        },
        {
          time: '13/Dec/2021(Mon)',
          tanPhu: '',
          binhDuong: 981,
          longBien: 1633,
          binhTan: 1428,
          haDong: 1535,
          leChan: 1706,
        },
        {
          time: '14/Dec/2021(Tue)',
          tanPhu: '',
          binhDuong: 378,
          longBien: 694,
          binhTan: 477,
          haDong: 711,
          leChan: 632,
        },
        {
          time: '15/Dec/2021(Wed)',
          tanPhu: '',
          binhDuong: 43,
          longBien: 93,
          binhTan: 96,
          haDong: 62,
          leChan: 169,
        },
        {
          time: '16/Dec/2021(Thu)',
          tanPhu: '',
          binhDuong: 4,
          longBien: 10,
          binhTan: 14,
          haDong: 29,
          leChan: 8,
        },
        {
          time: '17/Dec/2021(Fri)',
          tanPhu: '',
          binhDuong: 4,
          longBien: 10,
          binhTan: 14,
          haDong: 29,
          leChan: 8,
        },
        {
          time: '18/Dec/2021(Sat)',
          tanPhu: '',
          binhDuong: 4,
          longBien: 10,
          binhTan: 14,
          haDong: 29,
          leChan: 8,
        },
        {
          time: '19/Dec/2021(Sun)',
          tanPhu: '',
          binhDuong: 4,
          longBien: 10,
          binhTan: 14,
          haDong: 29,
          leChan: 8,
        },
        {
          time: '20/Dec/2021(Mon)',
          tanPhu: '',
          binhDuong: 4,
          longBien: 10,
          binhTan: 14,
          haDong: 29,
          leChan: 8,
        },
        {
          time: '21/Dec/2021(Tue)',
          tanPhu: '',
          binhDuong: 4,
          longBien: 10,
          binhTan: 14,
          haDong: 29,
          leChan: 8,
        },
        {
          time: '22/Dec/2021(Wed)',
          tanPhu: '',
          binhDuong: '',
          longBien: '',
          binhTan: '',
          haDong: '',
          leChan: '',
        },
        {
          time: '23/Dec/2021(Thu)',
          tanPhu: '',
          binhDuong: '',
          longBien: '',
          binhTan: '',
          haDong: '',
          leChan: '',
        },
        {
          time: '24/Dec/2021(Fri)',
          tanPhu: '',
          binhDuong: '',
          longBien: '',
          binhTan: '',
          haDong: '',
          leChan: '',
        },
        {
          time: '25/Dec/2021(Sat)',
          tanPhu: '',
          binhDuong: '',
          longBien: '',
          binhTan: '',
          haDong: '',
          leChan: '',
        },
        {
          time: '26/Dec/2021(Sun)',
          tanPhu: '',
          binhDuong: '',
          longBien: '',
          binhTan: '',
          haDong: '',
          leChan: '',
        },
        {
          time: '27/Dec/2021(Mon)',
          tanPhu: '',
          binhDuong: '',
          longBien: '',
          binhTan: '',
          haDong: '',
          leChan: '',
        },
        {
          time: '28/Dec/2021(Tue)',
          tanPhu: '',
          binhDuong: '',
          longBien: '',
          binhTan: '',
          haDong: '',
          leChan: '',
        },
        {
          time: '29/Dec/2021(Wed)',
          tanPhu: '',
          binhDuong: '',
          longBien: '',
          binhTan: '',
          haDong: '',
          leChan: '',
        },
        {
          time: '30/Dec/2021(Thu)',
          tanPhu: '',
          binhDuong: '',
          longBien: '',
          binhTan: '',
          haDong: '',
          leChan: '',
        },
        {
          time: '31/Dec/2021(Fri)',
          tanPhu: '',
          binhDuong: '',
          longBien: '',
          binhTan: '',
          haDong: '',
          leChan: '',
        },
      ],
      comparisonFooterData: [
        {
          time: 'Total',
          tanPhu: '',
          binhDuong: '360,265',
          longBien: '447,920',
          binhTan: '357,334',
          haDong: '374,189',
          leChan: '304,351',
        },
      ],
      seriesData: [
        {
          name: 'Tan Phu',
          data: [
            1, 2222, 1133, 2034, 2335, 3346, 1417, 2585, 6339, 1509, 1401, 1422, 4163, 4144, 3154, 1464, 5417, 4183, 5149, 2800, 7221, 43242, 43323, 55244, 4425, 5426, 4627, 4728, 2449, 8330, 3331,
          ],
        },
        {
          name: 'Binh Duong',
          data: [
            1222, 2233, 3344, 4455, 5566, 6677, 8877, 9988, 10099, 9100, 21101, 13112, 25123, 16134, 22145, 31156, 11167, 23178, 189, 200, 221, 232, 2343, 2354, 3265, 3206, 3127, 2228, 5299, 3230, 2331,
          ],
        },
        {
          name: 'Long Bien',
          data: [
            2132, 3222, 33333, 44243, 55304, 6635, 9717, 8328, 9935, 1025, 1301, 5112, 1223, 1343, 14345, 15635, 1567, 16738, 15839, 2007, 2231, 24332, 27453, 27454, 2675, 32066, 13257, 2248, 23499, 2330, 33321,
          ],
        },
        {
          name: 'Binh Tan',
          data: [
            132, 23222, 30333, 4244, 5355, 26556, 3776, 26868, 13909, 13400, 1301, 11422, 12353, 13442, 14504, 1156, 12467, 17048, 1389, 2000, 12241, 2232, 32443, 42544, 12465, 2206, 31237, 35228, 32959, 22430, 5331,
          ],
        },
        {
          name: 'Ha Dong',
          data: [
            1254, 2662, 3333, 4444, 5555, 6644, 7777, 8888, 9449, 1008, 1501, 11244, 12553, 1334, 1545, 16656, 16557, 14478, 18955, 5200, 5221, 2532, 2543, 24454, 2565, 2056, 1327, 2258, 25599, 2430, 3331,
          ],
        },
        {
          name: 'Le Chan',
          data: [
            1222, 2233, 3344, 4455, 5225, 6446, 5577, 8668, 7799, 1600, 13301, 12412, 14123, 11343, 16145, 21156, 10167, 8178, 9189, 6200, 22221, 11232, 10243, 254, 265, 11206, 22127, 44228, 22299, 230, 331,
          ],
        },
      ],
      legendData: [
        '01/Dec/2021(Wed)',
        '02/Dec/2021(Thu)',
        '03/Dec/2021(Fri)',
        '04/Dec/2021(Sat)',
        '05/Dec/2021(Sun)',
        '06/Dec/2021(Mon)',
        '07/Dec/2021(Tue)',
        '08/Dec/2021(Wed)',
        '09/Dec/2021(Thu)',
        '10/Dec/2021(Fri)',
        '11/Dec/2021(Sat)',
        '12/Dec/2021(Sun)',
        '13/Dec/2021(Mon)',
        '14/Dec/2021(Tue)',
        '15/Dec/2021(Wed)',
        '16/Dec/2021(Thu)',
        '17/Dec/2021(Fri)',
        '18/Dec/2021(Sat)',
        '19/Dec/2021(Sun)',
        '20/Dec/2021(Mon)',
        '21/Dec/2021(Tue)',
        '22/Dec/2021(Wed)',
        '23/Dec/2021(Thu)',
        '24/Dec/2021(Fri)',
        '25/Dec/2021(Sat)',
        '26/Dec/2021(Sun)',
        '27/Dec/2021(Mon)',
        '28/Dec/2021(Tue)',
        '29/Dec/2021(Wed)',
        '30/Dec/2021(Thu)',
        '31/Dec/2021(Fri)',
      ],
      comparisonMonthly2Data: [
        {
          time: '01/Dec/2021(Wed)',
          tanPhu_CurrentDate: '',
          tanPhu_SameDate: '',
          tanPhu_SameWeek: '',
          binhDuong_CurrentDate: '',
          binhDuong_SameDate: '',
          binhDuong_SameWeek: '',
          longBien_CurrentDate: '',
          longBien_SameDate: '',
          longBien_SameWeek: '',
          binhTan_CurrentDate: '',
          binhTan_SameDate: '',
          binhTan_SameWeek: '',
          haDong_CurrentDate: '',
          haDong_SameDate: '',
          haDong_SameWeek: '',
          leChan_CurrentDate: '',
          leChan_SameDate: '',
          leChan_SameWeek: '',
        },
        {
          time: '02/Dec/2021(Thu)',
          tanPhu_CurrentDate: '',
          tanPhu_SameDate: '',
          tanPhu_SameWeek: '',
          binhDuong_CurrentDate: '',
          binhDuong_SameDate: '',
          binhDuong_SameWeek: '',
          longBien_CurrentDate: '',
          longBien_SameDate: '',
          longBien_SameWeek: '',
          binhTan_CurrentDate: '',
          binhTan_SameDate: '',
          binhTan_SameWeek: '',
          haDong_CurrentDate: '',
          haDong_SameDate: '',
          haDong_SameWeek: '',
          leChan_CurrentDate: '',
          leChan_SameDate: '',
          leChan_SameWeek: '',
        },
        {
          time: '03/Dec/2021(Fri)',
          tanPhu_CurrentDate: '',
          tanPhu_SameDate: '',
          tanPhu_SameWeek: '',
          binhDuong_CurrentDate: '',
          binhDuong_SameDate: '',
          binhDuong_SameWeek: '',
          longBien_CurrentDate: '',
          longBien_SameDate: '',
          longBien_SameWeek: '',
          binhTan_CurrentDate: '',
          binhTan_SameDate: '',
          binhTan_SameWeek: '',
          haDong_CurrentDate: '',
          haDong_SameDate: '',
          haDong_SameWeek: '',
          leChan_CurrentDate: '',
          leChan_SameDate: '',
          leChan_SameWeek: '',
        },
        {
          time: '04/Dec/2021(Sat)',
          tanPhu_CurrentDate: '',
          tanPhu_SameDate: '',
          tanPhu_SameWeek: '',
          binhDuong_CurrentDate: '',
          binhDuong_SameDate: '',
          binhDuong_SameWeek: '',
          longBien_CurrentDate: '',
          longBien_SameDate: '',
          longBien_SameWeek: '',
          binhTan_CurrentDate: '',
          binhTan_SameDate: '',
          binhTan_SameWeek: '',
          haDong_CurrentDate: '',
          haDong_SameDate: '',
          haDong_SameWeek: '',
          leChan_CurrentDate: '',
          leChan_SameDate: '',
          leChan_SameWeek: '',
        },
        {
          time: '05/Dec/2021(Sun)',
          tanPhu_CurrentDate: '',
          tanPhu_SameDate: '',
          tanPhu_SameWeek: '',
          binhDuong_CurrentDate: '',
          binhDuong_SameDate: '',
          binhDuong_SameWeek: '',
          longBien_CurrentDate: '',
          longBien_SameDate: '',
          longBien_SameWeek: '',
          binhTan_CurrentDate: '',
          binhTan_SameDate: '',
          binhTan_SameWeek: '',
          haDong_CurrentDate: '',
          haDong_SameDate: '',
          haDong_SameWeek: '',
          leChan_CurrentDate: '',
          leChan_SameDate: '',
          leChan_SameWeek: '',
        },
        {
          time: '06/Dec/2021(Mon)',
          tanPhu_CurrentDate: '',
          tanPhu_SameDate: '',
          tanPhu_SameWeek: '',
          binhDuong_CurrentDate: '',
          binhDuong_SameDate: '',
          binhDuong_SameWeek: '',
          longBien_CurrentDate: '',
          longBien_SameDate: '',
          longBien_SameWeek: '',
          binhTan_CurrentDate: '',
          binhTan_SameDate: '',
          binhTan_SameWeek: '',
          haDong_CurrentDate: '',
          haDong_SameDate: '',
          haDong_SameWeek: '',
          leChan_CurrentDate: '',
          leChan_SameDate: '',
          leChan_SameWeek: '',
        },
        {
          time: '07/Dec/2021(Tue)',
          tanPhu_CurrentDate: '',
          tanPhu_SameDate: '',
          tanPhu_SameWeek: '',
          binhDuong_CurrentDate: '',
          binhDuong_SameDate: '',
          binhDuong_SameWeek: '',
          longBien_CurrentDate: '',
          longBien_SameDate: '',
          longBien_SameWeek: '',
          binhTan_CurrentDate: '',
          binhTan_SameDate: '',
          binhTan_SameWeek: '',
          haDong_CurrentDate: '',
          haDong_SameDate: '',
          haDong_SameWeek: '',
          leChan_CurrentDate: '',
          leChan_SameDate: '',
          leChan_SameWeek: '',
        },
        {
          time: '08/Dec/2021(Wed)',
          tanPhu_CurrentDate: '',
          tanPhu_SameDate: '',
          tanPhu_SameWeek: '',
          binhDuong_CurrentDate: '',
          binhDuong_SameDate: '',
          binhDuong_SameWeek: '',
          longBien_CurrentDate: '',
          longBien_SameDate: '',
          longBien_SameWeek: '',
          binhTan_CurrentDate: '',
          binhTan_SameDate: '',
          binhTan_SameWeek: '',
          haDong_CurrentDate: '',
          haDong_SameDate: '',
          haDong_SameWeek: '',
          leChan_CurrentDate: '',
          leChan_SameDate: '',
          leChan_SameWeek: '',
        },
        {
          time: '09/Dec/2021(Thu)',
          tanPhu_CurrentDate: '',
          tanPhu_SameDate: '',
          tanPhu_SameWeek: '',
          binhDuong_CurrentDate: '',
          binhDuong_SameDate: '',
          binhDuong_SameWeek: '',
          longBien_CurrentDate: '',
          longBien_SameDate: '',
          longBien_SameWeek: '',
          binhTan_CurrentDate: '',
          binhTan_SameDate: '',
          binhTan_SameWeek: '',
          haDong_CurrentDate: '',
          haDong_SameDate: '',
          haDong_SameWeek: '',
          leChan_CurrentDate: '',
          leChan_SameDate: '',
          leChan_SameWeek: '',
        },
        {
          time: '10/Dec/2021(Fri)',
          tanPhu_CurrentDate: '',
          tanPhu_SameDate: '',
          tanPhu_SameWeek: '',
          binhDuong_CurrentDate: '',
          binhDuong_SameDate: '',
          binhDuong_SameWeek: '',
          longBien_CurrentDate: '',
          longBien_SameDate: '',
          longBien_SameWeek: '',
          binhTan_CurrentDate: '',
          binhTan_SameDate: '',
          binhTan_SameWeek: '',
          haDong_CurrentDate: '',
          haDong_SameDate: '',
          haDong_SameWeek: '',
          leChan_CurrentDate: '',
          leChan_SameDate: '',
          leChan_SameWeek: '',
        },
        {
          time: '11/Dec/2021(Sat)',
          tanPhu_CurrentDate: '',
          tanPhu_SameDate: '',
          tanPhu_SameWeek: '',
          binhDuong_CurrentDate: '',
          binhDuong_SameDate: '',
          binhDuong_SameWeek: '',
          longBien_CurrentDate: '',
          longBien_SameDate: '',
          longBien_SameWeek: '',
          binhTan_CurrentDate: '',
          binhTan_SameDate: '',
          binhTan_SameWeek: '',
          haDong_CurrentDate: '',
          haDong_SameDate: '',
          haDong_SameWeek: '',
          leChan_CurrentDate: '',
          leChan_SameDate: '',
          leChan_SameWeek: '',
        },
        {
          time: '12/Dec/2021(Sun)',
          tanPhu_CurrentDate: '',
          tanPhu_SameDate: '',
          tanPhu_SameWeek: '',
          binhDuong_CurrentDate: '',
          binhDuong_SameDate: '',
          binhDuong_SameWeek: '',
          longBien_CurrentDate: '',
          longBien_SameDate: '',
          longBien_SameWeek: '',
          binhTan_CurrentDate: '',
          binhTan_SameDate: '',
          binhTan_SameWeek: '',
          haDong_CurrentDate: '',
          haDong_SameDate: '',
          haDong_SameWeek: '',
          leChan_CurrentDate: '',
          leChan_SameDate: '',
          leChan_SameWeek: '',
        },
        {
          time: '13/Dec/2021(Mon)',
          tanPhu_CurrentDate: '',
          tanPhu_SameDate: '',
          tanPhu_SameWeek: '',
          binhDuong_CurrentDate: '',
          binhDuong_SameDate: '',
          binhDuong_SameWeek: '',
          longBien_CurrentDate: '',
          longBien_SameDate: '',
          longBien_SameWeek: '',
          binhTan_CurrentDate: '',
          binhTan_SameDate: '',
          binhTan_SameWeek: '',
          haDong_CurrentDate: '',
          haDong_SameDate: '',
          haDong_SameWeek: '',
          leChan_CurrentDate: '',
          leChan_SameDate: '',
          leChan_SameWeek: '',
        },
        {
          time: '14/Dec/2021(Tue)',
          tanPhu_CurrentDate: '',
          tanPhu_SameDate: '',
          tanPhu_SameWeek: '',
          binhDuong_CurrentDate: '',
          binhDuong_SameDate: '',
          binhDuong_SameWeek: '',
          longBien_CurrentDate: '',
          longBien_SameDate: '',
          longBien_SameWeek: '',
          binhTan_CurrentDate: '',
          binhTan_SameDate: '',
          binhTan_SameWeek: '',
          haDong_CurrentDate: '',
          haDong_SameDate: '',
          haDong_SameWeek: '',
          leChan_CurrentDate: '',
          leChan_SameDate: '',
          leChan_SameWeek: '',
        },
        {
          time: '15/Dec/2021(Wed)',
          tanPhu_CurrentDate: '',
          tanPhu_SameDate: '',
          tanPhu_SameWeek: '',
          binhDuong_CurrentDate: '',
          binhDuong_SameDate: '',
          binhDuong_SameWeek: '',
          longBien_CurrentDate: '',
          longBien_SameDate: '',
          longBien_SameWeek: '',
          binhTan_CurrentDate: '',
          binhTan_SameDate: '',
          binhTan_SameWeek: '',
          haDong_CurrentDate: '',
          haDong_SameDate: '',
          haDong_SameWeek: '',
          leChan_CurrentDate: '',
          leChan_SameDate: '',
          leChan_SameWeek: '',
        },
        {
          time: '16/Dec/2021(Thu)',
          tanPhu_CurrentDate: '',
          tanPhu_SameDate: '',
          tanPhu_SameWeek: '',
          binhDuong_CurrentDate: '',
          binhDuong_SameDate: '',
          binhDuong_SameWeek: '',
          longBien_CurrentDate: '',
          longBien_SameDate: '',
          longBien_SameWeek: '',
          binhTan_CurrentDate: '',
          binhTan_SameDate: '',
          binhTan_SameWeek: '',
          haDong_CurrentDate: '',
          haDong_SameDate: '',
          haDong_SameWeek: '',
          leChan_CurrentDate: '',
          leChan_SameDate: '',
          leChan_SameWeek: '',
        },
        {
          time: '17/Dec/2021(Fri)',
          tanPhu_CurrentDate: '',
          tanPhu_SameDate: '',
          tanPhu_SameWeek: '',
          binhDuong_CurrentDate: '',
          binhDuong_SameDate: '',
          binhDuong_SameWeek: '',
          longBien_CurrentDate: '',
          longBien_SameDate: '',
          longBien_SameWeek: '',
          binhTan_CurrentDate: '',
          binhTan_SameDate: '',
          binhTan_SameWeek: '',
          haDong_CurrentDate: '',
          haDong_SameDate: '',
          haDong_SameWeek: '',
          leChan_CurrentDate: '',
          leChan_SameDate: '',
          leChan_SameWeek: '',
        },
        {
          time: '18/Dec/2021(Sat)',
          tanPhu_CurrentDate: '',
          tanPhu_SameDate: '',
          tanPhu_SameWeek: '',
          binhDuong_CurrentDate: '',
          binhDuong_SameDate: '',
          binhDuong_SameWeek: '',
          longBien_CurrentDate: '',
          longBien_SameDate: '',
          longBien_SameWeek: '',
          binhTan_CurrentDate: '',
          binhTan_SameDate: '',
          binhTan_SameWeek: '',
          haDong_CurrentDate: '',
          haDong_SameDate: '',
          haDong_SameWeek: '',
          leChan_CurrentDate: '',
          leChan_SameDate: '',
          leChan_SameWeek: '',
        },
        {
          time: '19/Dec/2021(Sun)',
          tanPhu_CurrentDate: '',
          tanPhu_SameDate: '',
          tanPhu_SameWeek: '',
          binhDuong_CurrentDate: '',
          binhDuong_SameDate: '',
          binhDuong_SameWeek: '',
          longBien_CurrentDate: '',
          longBien_SameDate: '',
          longBien_SameWeek: '',
          binhTan_CurrentDate: '',
          binhTan_SameDate: '',
          binhTan_SameWeek: '',
          haDong_CurrentDate: '',
          haDong_SameDate: '',
          haDong_SameWeek: '',
          leChan_CurrentDate: '',
          leChan_SameDate: '',
          leChan_SameWeek: '',
        },
        {
          time: '20/Dec/2021(Mon)',
          tanPhu_CurrentDate: '',
          tanPhu_SameDate: '',
          tanPhu_SameWeek: '',
          binhDuong_CurrentDate: '',
          binhDuong_SameDate: '',
          binhDuong_SameWeek: '',
          longBien_CurrentDate: '',
          longBien_SameDate: '',
          longBien_SameWeek: '',
          binhTan_CurrentDate: '',
          binhTan_SameDate: '',
          binhTan_SameWeek: '',
          haDong_CurrentDate: '',
          haDong_SameDate: '',
          haDong_SameWeek: '',
          leChan_CurrentDate: '',
          leChan_SameDate: '',
          leChan_SameWeek: '',
        },
        {
          time: '21/Dec/2021(Tue)',
          tanPhu_CurrentDate: '',
          tanPhu_SameDate: '',
          tanPhu_SameWeek: '',
          binhDuong_CurrentDate: '',
          binhDuong_SameDate: '',
          binhDuong_SameWeek: '',
          longBien_CurrentDate: '',
          longBien_SameDate: '',
          longBien_SameWeek: '',
          binhTan_CurrentDate: '',
          binhTan_SameDate: '',
          binhTan_SameWeek: '',
          haDong_CurrentDate: '',
          haDong_SameDate: '',
          haDong_SameWeek: '',
          leChan_CurrentDate: '',
          leChan_SameDate: '',
          leChan_SameWeek: '',
        },
        {
          time: '22/Dec/2021(Wed)',
          tanPhu_CurrentDate: '',
          tanPhu_SameDate: '',
          tanPhu_SameWeek: '',
          binhDuong_CurrentDate: '',
          binhDuong_SameDate: '',
          binhDuong_SameWeek: '',
          longBien_CurrentDate: '',
          longBien_SameDate: '',
          longBien_SameWeek: '',
          binhTan_CurrentDate: '',
          binhTan_SameDate: '',
          binhTan_SameWeek: '',
          haDong_CurrentDate: '',
          haDong_SameDate: '',
          haDong_SameWeek: '',
          leChan_CurrentDate: '',
          leChan_SameDate: '',
          leChan_SameWeek: '',
        },
        {
          time: '23/Dec/2021(Thu)',
          tanPhu_CurrentDate: '',
          tanPhu_SameDate: '',
          tanPhu_SameWeek: '',
          binhDuong_CurrentDate: '',
          binhDuong_SameDate: '',
          binhDuong_SameWeek: '',
          longBien_CurrentDate: '',
          longBien_SameDate: '',
          longBien_SameWeek: '',
          binhTan_CurrentDate: '',
          binhTan_SameDate: '',
          binhTan_SameWeek: '',
          haDong_CurrentDate: '',
          haDong_SameDate: '',
          haDong_SameWeek: '',
          leChan_CurrentDate: '',
          leChan_SameDate: '',
          leChan_SameWeek: '',
        },
        {
          time: '24/Dec/2021(Fri)',
          tanPhu_CurrentDate: '',
          tanPhu_SameDate: '',
          tanPhu_SameWeek: '',
          binhDuong_CurrentDate: '',
          binhDuong_SameDate: '',
          binhDuong_SameWeek: '',
          longBien_CurrentDate: '',
          longBien_SameDate: '',
          longBien_SameWeek: '',
          binhTan_CurrentDate: '',
          binhTan_SameDate: '',
          binhTan_SameWeek: '',
          haDong_CurrentDate: '',
          haDong_SameDate: '',
          haDong_SameWeek: '',
          leChan_CurrentDate: '',
          leChan_SameDate: '',
          leChan_SameWeek: '',
        },
        {
          time: '25/Dec/2021(Sat)',
          tanPhu_CurrentDate: '',
          tanPhu_SameDate: '',
          tanPhu_SameWeek: '',
          binhDuong_CurrentDate: '',
          binhDuong_SameDate: '',
          binhDuong_SameWeek: '',
          longBien_CurrentDate: '',
          longBien_SameDate: '',
          longBien_SameWeek: '',
          binhTan_CurrentDate: '',
          binhTan_SameDate: '',
          binhTan_SameWeek: '',
          haDong_CurrentDate: '',
          haDong_SameDate: '',
          haDong_SameWeek: '',
          leChan_CurrentDate: '',
          leChan_SameDate: '',
          leChan_SameWeek: '',
        },
        {
          time: '26/Dec/2021(Sun)',
          tanPhu_CurrentDate: '',
          tanPhu_SameDate: '',
          tanPhu_SameWeek: '',
          binhDuong_CurrentDate: '',
          binhDuong_SameDate: '',
          binhDuong_SameWeek: '',
          longBien_CurrentDate: '',
          longBien_SameDate: '',
          longBien_SameWeek: '',
          binhTan_CurrentDate: '',
          binhTan_SameDate: '',
          binhTan_SameWeek: '',
          haDong_CurrentDate: '',
          haDong_SameDate: '',
          haDong_SameWeek: '',
          leChan_CurrentDate: '',
          leChan_SameDate: '',
          leChan_SameWeek: '',
        },
        {
          time: '27/Dec/2021(Mon)',
          tanPhu_CurrentDate: '',
          tanPhu_SameDate: '',
          tanPhu_SameWeek: '',
          binhDuong_CurrentDate: '',
          binhDuong_SameDate: '',
          binhDuong_SameWeek: '',
          longBien_CurrentDate: '',
          longBien_SameDate: '',
          longBien_SameWeek: '',
          binhTan_CurrentDate: '',
          binhTan_SameDate: '',
          binhTan_SameWeek: '',
          haDong_CurrentDate: '',
          haDong_SameDate: '',
          haDong_SameWeek: '',
          leChan_CurrentDate: '',
          leChan_SameDate: '',
          leChan_SameWeek: '',
        },
        {
          time: '28/Dec/2021(Tue)',
          tanPhu_CurrentDate: '',
          tanPhu_SameDate: '',
          tanPhu_SameWeek: '',
          binhDuong_CurrentDate: '',
          binhDuong_SameDate: '',
          binhDuong_SameWeek: '',
          longBien_CurrentDate: '',
          longBien_SameDate: '',
          longBien_SameWeek: '',
          binhTan_CurrentDate: '',
          binhTan_SameDate: '',
          binhTan_SameWeek: '',
          haDong_CurrentDate: '',
          haDong_SameDate: '',
          haDong_SameWeek: '',
          leChan_CurrentDate: '',
          leChan_SameDate: '',
          leChan_SameWeek: '',
        },
        {
          time: '29/Dec/2021(Wed)',
          tanPhu_CurrentDate: '',
          tanPhu_SameDate: '',
          tanPhu_SameWeek: '',
          binhDuong_CurrentDate: '',
          binhDuong_SameDate: '',
          binhDuong_SameWeek: '',
          longBien_CurrentDate: '',
          longBien_SameDate: '',
          longBien_SameWeek: '',
          binhTan_CurrentDate: '',
          binhTan_SameDate: '',
          binhTan_SameWeek: '',
          haDong_CurrentDate: '',
          haDong_SameDate: '',
          haDong_SameWeek: '',
          leChan_CurrentDate: '',
          leChan_SameDate: '',
          leChan_SameWeek: '',
        },
        {
          time: '30/Dec/2021(Thu)',
          tanPhu_CurrentDate: '',
          tanPhu_SameDate: '',
          tanPhu_SameWeek: '',
          binhDuong_CurrentDate: '',
          binhDuong_SameDate: '',
          binhDuong_SameWeek: '',
          longBien_CurrentDate: '',
          longBien_SameDate: '',
          longBien_SameWeek: '',
          binhTan_CurrentDate: '',
          binhTan_SameDate: '',
          binhTan_SameWeek: '',
          haDong_CurrentDate: '',
          haDong_SameDate: '',
          haDong_SameWeek: '',
          leChan_CurrentDate: '',
          leChan_SameDate: '',
          leChan_SameWeek: '',
        },
        {
          time: '31/Dec/2021(Fri)',
          tanPhu_CurrentDate: '',
          tanPhu_SameDate: '',
          tanPhu_SameWeek: '',
          binhDuong_CurrentDate: '',
          binhDuong_SameDate: '',
          binhDuong_SameWeek: '',
          longBien_CurrentDate: '',
          longBien_SameDate: '',
          longBien_SameWeek: '',
          binhTan_CurrentDate: '',
          binhTan_SameDate: '',
          binhTan_SameWeek: '',
          haDong_CurrentDate: '',
          haDong_SameDate: '',
          haDong_SameWeek: '',
          leChan_CurrentDate: '',
          leChan_SameDate: '',
          leChan_SameWeek: '',
        },
      ],
      comparisonMonthly2FooterData: [
        {
          time: 'Total',
          tanPhu_CurrentDate: '',
          tanPhu_SameDate: '1,365,841',
          tanPhu_SameWeek: '1,419,256',
          binhDuong_CurrentDate: '360,265',
          binhDuong_SameDate: '',
          binhDuong_SameWeek: '',
          longBien_CurrentDate: '447,920',
          longBien_SameDate: '',
          longBien_SameWeek: '',
          binhTan_CurrentDate: '357,334',
          binhTan_SameDate: '',
          binhTan_SameWeek: '',
          haDong_CurrentDate: '374,189',
          haDong_SameDate: '',
          haDong_SameWeek: '',
          leChan_CurrentDate: '304,351',
          leChan_SameDate: '',
          leChan_SameWeek: '',
        },
      ],
    },
  ],
}

// ------------------------------------------------
// GET: Return Single data
// ------------------------------------------------
mock.onGet(/\/apps\/comparison\/dates\/\d+/).reply(config => {
  // Get event id from URL
  let id = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  id = Number(id)

  const index = data.dates.findIndex(e => e.id === id)
  const dat = data.dates[index]
  Object.assign(dat, {
    taskDone: 1230,
    projectDone: 568,
    taxId: 'Tax-8894',
    language: 'English',
  })

  if (dat) return [200, dat]

  return [404]
})
