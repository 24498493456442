const { default: mock } = require('@/@fake-db/mock')

/* eslint-disable global-require */
// data
const data = {
  dates: [
    {
      id: 2,
      searchType: 1,
      text: '06/Oct/2021 (Tue)',
      image_src: '/images/mollImage/16/corridor.png',
      image_witdh: '970px',
      image_height: '2188px',
      seriesData: [
        {
          name: 'Cor 01',
          data: [
            120, 1132, 1101, 1134, 190, 230, 210, 1124, 655, 1144, 1133, 222, 144, 678, 33, 12,
          ],
        },
        {
          name: 'Cor 02',
          data: [
            220, 182, 191, 234, 290, 330, 310, 122, 345, 67, 43, 23, 445, 33, 55, 44,
          ],
        },
        {
          name: 'Cor 03',
          data: [
            150, 232, 201, 154, 190, 330, 644, 322, 566, 335, 332, 123, 234, 432, 410,
            0,
          ],
        },
        {
          name: 'Cor 04',
          data: [
            320, 332, 301, 334, 390, 330, 320, 150, 232, 201, 154, 190, 111, 89, 13,
            44,
          ],
        },
        {
          name: 'Cor 05',
          data: [
            80, 132, 101, 234, 140, 110, 320, 301, 334, 390, 330, 320, 150, 24, 53, 0,
          ],
        },
        {
          name: 'Cor 06',
          data: [
            20, 92, 91, 94, 120, 130, 120, 11, 234, 140, 110, 320, 31, 234, 140, 110,
          ],
        },
        {
          name: 'Cor 08',
          data: [
            180, 132, 101, 134, 290, 130, 320, 345, 21, 45, 88, 55, 468, 55, 33, 55,
          ],
        },
        {
          name: 'Cor 09',
          data: [
            320, 132, 701, 634, 790, 830, 500, 245, 563, 246, 764, 556, 445, 774, 33,
            77,
          ],
        },
        {
          name: 'Cor 10',
          data: [80, 92, 91, 94, 120, 130, 130, 88, 54, 22, 33, 44, 66, 77, 88, 23],
        },
        {
          name: 'Cor 11',
          data: [0, 2, 90, 93, 129, 133, 132, 64, 35, 66, 33, 32, 22, 56, 33, 456],
        },
        {
          name: 'Cor 36',
          data: [
            820, 932, 32, 93, 129, 133, 132, 64, 35, 66, 33, 32, 164, 320, 432, 16,
          ],
        },
        {
          name: 'Cor 12',
          data: [0, 93, 90, 54, 120, 130, 132, 65, 34, 67, 35, 655, 34, 0, 644, 33],
        },
        {
          name: 'Cor 13',
          data: [80, 92, 12, 93, 120, 10, 120, 53, 33, 556, 23, 22, 45, 674, 22, 56],
        },
        {
          name: 'Cor 14',
          data: [
            82, 92, 91, 94, 10, 130, 10, 64, 345, 554, 335, 67, 443, 356, 445, 23,
          ],
        },
        {
          name: 'Cor 15',
          data: [0, 32, 36, 34, 10, 30, 20, 12, 44, 56, 33, 66, 33, 55, 77, 89],
        },
        {
          name: 'Cor 16',
          data: [0, 32, 36, 34, 10, 30, 20, 12, 44, 56, 33, 66, 33, 55, 77, 89],
        },
        {
          name: 'Cor 17',
          data: [0, 32, 36, 34, 10, 30, 20, 12, 44, 56, 33, 66, 33, 55, 77, 89],
        },
        {
          name: 'Cor 18',
          data: [0, 32, 36, 34, 10, 30, 20, 12, 44, 56, 33, 66, 33, 55, 77, 89],
        },
        {
          name: 'Cor 19',
          data: [0, 32, 36, 34, 10, 30, 20, 12, 44, 56, 33, 66, 33, 55, 77, 89],
        },
        {
          name: 'Cor 20',
          data: [0, 32, 36, 34, 10, 30, 20, 12, 44, 56, 33, 66, 33, 55, 77, 8],
        },
        {
          name: 'Cor 21',
          data: [0, 32, 36, 34, 10, 30, 20, 12, 44, 56, 33, 66, 33, 55, 77, 8],
        },
        {
          name: 'Cor 22',
          data: [0, 32, 36, 34, 10, 30, 20, 12, 44, 56, 33, 66, 33, 55, 77, 89],
        },
        {
          name: 'Cor 23',
          data: [0, 32, 36, 34, 10, 30, 20, 12, 44, 56, 33, 66, 33, 55, 77, 89],
        },
        {
          name: 'Cor 37',
          data: [0, 32, 36, 34, 10, 30, 20, 12, 44, 56, 33, 66, 33, 55, 77, 89],
        },
        {
          name: 'Cor 24',
          data: [0, 32, 36, 34, 10, 30, 20, 12, 44, 56, 33, 66, 33, 55, 77, 89],
        },
        {
          name: 'Cor 25',
          data: [0, 32, 36, 34, 10, 30, 20, 12, 44, 56, 33, 66, 33, 55, 77, 8],
        },
        {
          name: 'Cor 26',
          data: [0, 32, 36, 34, 10, 30, 20, 12, 44, 56, 33, 66, 33, 55, 77, 89],
        },
        {
          name: 'Cor 27',
          data: [0, 32, 36, 34, 10, 30, 20, 12, 44, 56, 33, 66, 33, 55, 77, 8],
        },
        {
          name: 'Cor 28',
          data: [0, 32, 36, 34, 10, 30, 20, 12, 44, 56, 33, 66, 33, 55, 77, 89],
        },
        {
          name: 'Cor 29',
          data: [0, 32, 36, 34, 10, 30, 20, 12, 44, 56, 33, 66, 33, 55, 77, 89],
        },
        {
          name: 'Cor 30',
          data: [0, 32, 36, 34, 10, 30, 20, 12, 44, 56, 33, 66, 33, 55, 77, 89],
        },
        {
          name: 'Cor 31',
          data: [0, 32, 36, 34, 10, 30, 20, 12, 44, 56, 33, 66, 33, 55, 77, 89],
        },
        {
          name: 'Cor 32',
          data: [0, 32, 36, 34, 10, 30, 20, 12, 44, 56, 33, 66, 33, 55, 77, 89],
        },
        {
          name: 'Cor 33',
          data: [0, 32, 36, 34, 10, 30, 20, 12, 44, 56, 33, 66, 33, 55, 77, 89],
        },
        {
          name: 'Cor 34',
          data: [0, 32, 36, 34, 10, 30, 20, 12, 44, 56, 33, 66, 33, 55, 77, 89],
        },
        {
          name: 'Cor 35',
          data: [0, 32, 36, 34, 10, 30, 20, 12, 44, 56, 33, 66, 33, 55, 77, 89],
        },
        {
          name: 'Cor 36',
          data: [0, 32, 36, 34, 10, 30, 20, 12, 44, 56, 33, 66, 33, 55, 77, 89],
        },
        {
          name: 'Cor 37',
          data: [0, 32, 36, 34, 10, 30, 20, 12, 44, 56, 33, 66, 33, 55, 77, 89],
        },
        {
          name: 'Cor 38',
          data: [0, 32, 36, 34, 10, 30, 20, 12, 44, 56, 33, 66, 33, 55, 77, 89],
        },
        {
          name: 'Cor 39',
          data: [0, 32, 36, 34, 10, 30, 20, 12, 44, 56, 33, 66, 33, 55, 77, 89],
        },
        {
          name: 'Cor 40',
          data: [0, 32, 36, 34, 10, 30, 20, 12, 44, 56, 33, 66, 33, 55, 77, 89],
        },
      ],
      legendData: [
        '08-09',
        '09-10',
        '10-11',
        '11-12',
        '12-13',
        '13-14',
        '14-15',
        '15-16',
        '16-17',
        '17-18',
        '18-19',
        '19-20',
        '20-21',
        '21-22',
        '22-23',
        '23-00',
      ],
      table1Data: [
        {
          time: '08-09',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
        },
        {
          time: '08-09',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
        },
        {
          time: '09-10',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
        },
        {
          time: '09-10',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
        },
        {
          time: '10-11',
          count1: 123,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
        },
        {
          time: '10-11',
          count1: 123,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
        },
        {
          time: '11-12',
          count1: '111',
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
        },
        {
          time: '11-12',
          count1: '111',
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
        },
        {
          time: '12-13',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
        },
        {
          time: '12-13',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
        },
        {
          time: '13-14',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
        },
        {
          time: '13-14',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
        },
        {
          time: '14-15',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
        },
        {
          time: '14-15',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
        },
        {
          time: '15-16',
          count1: '112',
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
        },
        {
          time: '15-16',
          count1: '112',
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
        },
        {
          time: '16-17',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
        },
        {
          time: '16-17',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
        },
        {
          time: '17-18',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
        },
        {
          time: '17-18',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
        },
        {
          time: '18-19',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
        },
        {
          time: '18-19',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
        },
        {
          time: '19-20',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
        },
        {
          time: '19-20',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
        },
        {
          time: '20-21',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
        },
        {
          time: '20-21',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
        },
        {
          time: '21-22',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
        },
        {
          time: '21-22',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
        },
        {
          time: '22-23',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
        },
        {
          time: '22-23',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,

        },
        {
          time: '23-00',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
        },
        {
          time: '23-00',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
        },
      ],
      table1FooterData: [
        {
          time: 'Cor TTL (L/R)',
          count1: '112,159',
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
          count20: 343,
          count21: 343,
          count22: 343,
        },
        {
          time: 'Cor TTL (L+R)',
          count1: '343',
          count2: '1,343',
          count3: '1,343',
          count4: '1,343',
          count5: '1,343',
          count6: '1,343',
          count7: '1,343',
          count8: '1,343',
          count9: '1,343',
          count10: '1,343',
          count11: '1,343',
        },
        {
          time: 'Block TTL (L/R)',
          count1: 'L←',
          count2: '→R',
          count3: 'L←',
          count4: '→R',
          count5: '-',
          count6: '-',
          count7: '-',
          count8: '-',
          count9: '-',
          count10: '-',
          count11: '-',
          count12: '-',
          count13: 'L←',
          count14: '→R',
        },
        {
          time: 'Block TTL (L/R)',
          count1: '11,862',
          count2: '12,637',
          count3: '11,261',
          count4: '9,796',
          count5: '-',
          count6: '-',
          count7: '-',
          count8: '-',
          count9: '-',
          count10: '-',
          count11: '-',
          count12: '-',
          count13: '2,012',
          count14: '2,114',
        },
        {
          time: 'Block TTL (L+R)',
          count1: '24,499',
          count2: '21,057',
          count3: '6,293',
          count4: '9,868',
          count5: '3,560',
          count6: '7,497',
          count7: '4,126',
        },
        {
          time: 'Block Ratio',
          count1: '11.4%',
          count2: '13.6%',
          count3: '4.1%',
          count4: '6.4%',
          count5: '2.3%',
          count6: '4.8%',
          count7: '2.7%',
        },
        {
          time: 'Floor TTL',
          count1: '76,900',
        },
        {
          time: 'Floor Ratio',
          count1: '49.7%',
        },
      ],
      table2Data: [
        {
          time: '08-09',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
          count23: 355,
          count24: 355,
          count25: 355,
          count26: 355,
        },
        {
          time: '08-09',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
        },
        {
          time: '09-10',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
          count23: 355,
          count24: 355,
          count25: 355,
          count26: 355,
        },
        {
          time: '09-10',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
        },
        {
          time: '10-11',
          count1: 123,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
          count23: 355,
          count24: 355,
          count25: 355,
          count26: 355,
        },
        {
          time: '10-11',
          count1: 123,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
        },
        {
          time: '11-12',
          count1: '111',
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
          count23: 355,
          count24: 355,
          count25: 355,
          count26: 355,
        },
        {
          time: '11-12',
          count1: '111',
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
        },
        {
          time: '12-13',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
          count23: 355,
          count24: 355,
          count25: 355,
          count26: 355,
        },
        {
          time: '12-13',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
        },
        {
          time: '13-14',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
          count23: 355,
          count24: 355,
          count25: 355,
          count26: 355,
        },
        {
          time: '13-14',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
        },
        {
          time: '14-15',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
          count23: 355,
          count24: 355,
          count25: 355,
          count26: 355,
        },
        {
          time: '14-15',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
        },
        {
          time: '15-16',
          count1: '112',
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
          count23: 355,
          count24: 355,
          count25: 355,
          count26: 355,
        },
        {
          time: '15-16',
          count1: '112',
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
        },
        {
          time: '16-17',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
          count23: 355,
          count24: 355,
          count25: 355,
          count26: 355,
        },
        {
          time: '16-17',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
        },
        {
          time: '17-18',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
          count23: 355,
          count24: 355,
          count25: 355,
          count26: 355,
        },
        {
          time: '17-18',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
        },
        {
          time: '18-19',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
          count23: 355,
          count24: 355,
          count25: 355,
          count26: 355,
        },
        {
          time: '18-19',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
        },
        {
          time: '19-20',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
          count23: 355,
          count24: 355,
          count25: 355,
          count26: 355,
        },
        {
          time: '19-20',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
        },
        {
          time: '20-21',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
          count23: 355,
          count24: 355,
          count25: 355,
          count26: 355,
        },
        {
          time: '20-21',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
        },
        {
          time: '21-22',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
          count23: 355,
          count24: 355,
          count25: 355,
          count26: 355,
        },
        {
          time: '21-22',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
        },
        {
          time: '22-23',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
          count23: 355,
          count24: 355,
          count25: 355,
          count26: 355,
        },
        {
          time: '22-23',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
        },
        {
          time: '23-00',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
          count23: 355,
          count24: 355,
          count25: 355,
          count26: 355,
        },
        {
          time: '23-00',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
        },
      ],
      table2FooterData: [
        {
          time: 'Cor TTL (L/R)',
          count1: '112,159',
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
          count20: 343,
          count21: 343,
          count22: 343,
          count23: 355,
          count24: 355,
          count25: 355,
          count26: 355,
        },
        {
          time: 'Cor TTL (L+R)',
          count1: '343',
          count2: '1,343',
          count3: '1,343',
          count4: '1,343',
          count5: '1,343',
          count6: '1,343',
          count7: '1,343',
          count8: '1,343',
          count9: '1,343',
          count10: '1,343',
          count11: '1,343',
          count12: '1,343',
          count13: '1,343',
        },
        {
          time: 'Block TTL (L/R)',
          count1: 'L←',
          count2: '→R',
          count3: 'L←',
          count4: '→R',
          count5: '-',
          count6: '-',
          count7: 'L←',
          count8: '→R',
          count9: '-',
          count10: '-',
          count11: 'L←',
          count12: '→R',
          count13: 'L←',
          count14: '→R',
        },
        {
          time: 'Block TTL (L/R)',
          count1: '11,862',
          count2: '12,637',
          count3: '11,261',
          count4: '9,796',
          count5: '-',
          count6: '-',
          count7: '1,242',
          count8: '4,212',
          count9: '-',
          count10: '-',
          count11: '213',
          count12: '422',
          count13: '2,012',
          count14: '2,114',
        },
        {
          time: 'Block TTL (L+R)',
          count1: '24,499',
          count2: '21,057',
          count3: '6,293',
          count4: '9,868',
          count5: '3,560',
          count6: '7,497',
          count7: '4,126',
        },
        {
          time: 'Block Ratio',
          count1: '11.4%',
          count2: '13.6%',
          count3: '4.1%',
          count4: '6.4%',
          count5: '2.3%',
          count6: '4.8%',
          count7: '2.7%',
        },
        {
          time: 'Floor TTL',
          count1: '76,900',
        },
        {
          time: 'Floor Ratio',
          count1: '49.7%',
        },
      ],
      table3Data: [
        {
          time: '08-09',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
          count23: 355,
          count24: 355,
          count25: 355,
          count26: 355,
          count27: 355,
          count28: 355,
          count29: 355,
          count30: 355,
        },
        {
          time: '08-09',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
        },
        {
          time: '09-10',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
          count23: 355,
          count24: 355,
          count25: 355,
          count26: 355,
          count27: 355,
          count28: 355,
          count29: 355,
          count30: 355,
        },
        {
          time: '09-10',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
        },
        {
          time: '10-11',
          count1: 123,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
          count23: 355,
          count24: 355,
          count25: 355,
          count26: 355,
          count27: 355,
          count28: 355,
          count29: 355,
          count30: 355,
        },
        {
          time: '10-11',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
        },
        {
          time: '11-12',
          count1: '111',
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
          count23: 355,
          count24: 355,
          count25: 355,
          count26: 355,
          count27: 355,
          count28: 355,
          count29: 355,
          count30: 355,
        },
        {
          time: '11-12',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
        },
        {
          time: '12-13',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
          count23: 355,
          count24: 355,
          count25: 355,
          count26: 355,
          count27: 355,
          count28: 355,
          count29: 355,
          count30: 355,
        },
        {
          time: '12-13',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
        },
        {
          time: '13-14',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
          count23: 355,
          count24: 355,
          count25: 355,
          count26: 355,
          count27: 355,
          count28: 355,
          count29: 355,
          count30: 355,
        },
        {
          time: '13-14',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
        },
        {
          time: '14-15',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
          count23: 355,
          count24: 355,
          count25: 355,
          count26: 355,
          count27: 355,
          count28: 355,
          count29: 355,
          count30: 355,
        },
        {
          time: '14-15',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
        },
        {
          time: '15-16',
          count1: '112',
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
          count23: 355,
          count24: 355,
          count25: 355,
          count26: 355,
          count27: 355,
          count28: 355,
          count29: 355,
          count30: 355,
        },
        {
          time: '15-16',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
        },
        {
          time: '16-17',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
          count23: 355,
          count24: 355,
          count25: 355,
          count26: 355,
          count27: 355,
          count28: 355,
          count29: 355,
          count30: 355,
        },
        {
          time: '16-17',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
        },
        {
          time: '17-18',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
          count23: 355,
          count24: 355,
          count25: 355,
          count26: 355,
          count27: 355,
          count28: 355,
          count29: 355,
          count30: 355,
        },
        {
          time: '17-18',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
        },
        {
          time: '18-19',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
          count23: 355,
          count24: 355,
          count25: 355,
          count26: 355,
          count27: 355,
          count28: 355,
          count29: 355,
          count30: 355,
        },
        {
          time: '18-19',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
        },
        {
          time: '19-20',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
          count23: 355,
          count24: 355,
          count25: 355,
          count26: 355,
          count27: 355,
          count28: 355,
          count29: 355,
          count30: 355,
        },
        {
          time: '19-20',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
        },
        {
          time: '20-21',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
          count23: 355,
          count24: 355,
          count25: 355,
          count26: 355,
          count27: 355,
          count28: 355,
          count29: 355,
          count30: 355,
        },
        {
          time: '20-21',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
        },
        {
          time: '21-22',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
          count23: 355,
          count24: 355,
          count25: 355,
          count26: 355,
          count27: 355,
          count28: 355,
          count29: 355,
          count30: 355,
        },
        {
          time: '21-22',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
        },
        {
          time: '22-23',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
          count23: 355,
          count24: 355,
          count25: 355,
          count26: 355,
          count27: 355,
          count28: 355,
          count29: 355,
          count30: 355,
        },
        {
          time: '22-23',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
        },
        {
          time: '23-00',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
          count23: 355,
          count24: 355,
          count25: 355,
          count26: 355,
          count27: 355,
          count28: 355,
          count29: 355,
          count30: 355,
        },
        {
          time: '23-00',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
        },
      ],
      table3FooterData: [
        {
          time: 'Cor TTL (L/R)',
          count1: '112,159',
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
          count20: 343,
          count21: 343,
          count22: 343,
          count23: 355,
          count24: 355,
          count25: 355,
          count26: 355,
          count27: 355,
          count28: 355,
          count29: 355,
          count30: 355,
        },
        {
          time: 'Cor TTL (L+R)',
          count1: '343',
          count2: '1,343',
          count3: '1,343',
          count4: '1,343',
          count5: '1,343',
          count6: '1,343',
          count7: '1,343',
          count8: '1,343',
          count9: '1,343',
          count10: '1,343',
          count11: '1,343',
          count12: '1,343',
          count13: '1,343',
          count14: '1,343',
          count15: '1,343',
        },
        {
          time: 'Block TTL (L/R)',
          count1: 'L←',
          count2: '→R',
          count3: 'L←',
          count4: '→R',
          count5: '-',
          count6: '-',
          count7: 'L←',
          count8: '→R',
          count9: 'U↑',
          count10: 'D↓',
          count11: '-',
          count12: '-',
          count13: 'L←',
          count14: '→R',
          count15: 'U↑',
          count16: 'D↓',
        },
        {
          time: 'Block TTL (L/R)',
          count1: '11,862',
          count2: '12,637',
          count3: '11,261',
          count4: '9,796',
          count5: '-',
          count6: '-',
          count7: '24,232',
          count8: '24,232',
          count9: '24,232',
          count10: '124,232',
          count11: '-',
          count12: '-',
          count13: '2,012',
          count14: '2,114',
          count15: '2,012',
          count16: '2,114',
        },
        {
          time: 'Block TTL (L+R)',
          count1: '24,499',
          count2: '21,057',
          count3: '6,293',
          count4: '9,868',
          count5: '3,560',
          count6: '7,497',
          count7: '4,126',
          count8: '4,026',
        },
        {
          time: 'Block Ratio',
          count1: '11.4%',
          count2: '13.6%',
          count3: '4.1%',
          count4: '6.4%',
          count5: '2.3%',
          count6: '4.8%',
          count7: '2.7%',
          count8: '2.7%',
        },
        {
          time: 'Floor TTL',
          count1: '76,900',
        },
        {
          time: 'Floor Ratio',
          count1: '49.7%',
        },
      ],
    },
    {
      id: 1,
      searchType: 2,
      image_src: '/images/mollImage/16/corridor.png',
      image_witdh: '970px',
      image_height: '2188px',
      table1Data: [
        {
          time: '01/Nov/2021(Mon)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
        },
        {
          time: '02/Nov/2021(Tue)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
        },
        {
          time: '03/Nov/2021(Wed)',
          count1: 123,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
        },
        {
          time: '04/Nov/2021(Thu)',
          count1: '111',
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
        },
        {
          time: '05/Nov/2021(Fri)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
        },
        {
          time: '06/Nov/2021(Sat)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
        },
        {
          time: '07/Nov/2021(Sun)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
        },
        {
          time: '08/Nov/2021(Mon)',
          count1: '112',
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
        },
        {
          time: '09/Nov/2021(Tue)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
        },
        {
          time: '10/Nov/2021(Wed)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
        },
        {
          time: '11/Nov/2021(Thu)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
        },
        {
          time: '12/Nov/2021(Fri)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
        },
        {
          time: '13/Nov/2021(Sat)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
        },
      ],
      table1FooterData: [
        {
          time: 'Cor TTL (L/R)',
          count1: '112,159',
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
          count20: 343,
          count21: 343,
          count22: 343,
        },
        {
          time: 'Cor TTL (L+R)',
          count1: '343',
          count2: '1,343',
          count3: '1,343',
          count4: '1,343',
          count5: '1,343',
          count6: '1,343',
          count7: '1,343',
          count8: '1,343',
          count9: '1,343',
          count10: '1,343',
          count11: '1,343',
        },
        {
          time: 'Block TTL (L/R)',
          count1: 'L←',
          count2: '→R',
          count3: 'L←',
          count4: '→R',
          count5: '-',
          count6: '-',
          count7: '-',
          count8: '-',
          count9: '-',
          count10: '-',
          count11: '-',
          count12: '-',
          count13: 'L←',
          count14: '→R',
        },
        {
          time: 'Block TTL (L/R)',
          count1: '11,862',
          count2: '12,637',
          count3: '11,261',
          count4: '9,796',
          count5: '-',
          count6: '-',
          count7: '-',
          count8: '-',
          count9: '-',
          count10: '-',
          count11: '-',
          count12: '-',
          count13: '2,012',
          count14: '2,114',
        },
        {
          time: 'Block TTL (L+R)',
          count1: '24,499',
          count2: '21,057',
          count3: '6,293',
          count4: '9,868',
          count5: '3,560',
          count6: '7,497',
          count7: '4,126',
        },
        {
          time: 'Block Ratio',
          count1: '11.4%',
          count2: '13.6%',
          count3: '4.1%',
          count4: '6.4%',
          count5: '2.3%',
          count6: '4.8%',
          count7: '2.7%',
        },
        {
          time: 'Floor TTL',
          count1: '76,900',
        },
        {
          time: 'Floor Ratio',
          count1: '49.7%',
        },
      ],
      table2Data: [
        {
          time: '01/Nov/2021(Mon)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
          count23: 355,
          count24: 355,
          count25: 355,
          count26: 355,
        },
        {
          time: '02/Nov/2021(Tue)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
          count23: 355,
          count24: 355,
          count25: 355,
          count26: 355,
        },
        {
          time: '03/Nov/2021(Wed)',
          count1: 123,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
          count23: 355,
          count24: 355,
          count25: 355,
          count26: 355,
        },
        {
          time: '04/Nov/2021(Thu)',
          count1: '111',
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
          count23: 355,
          count24: 355,
          count25: 355,
          count26: 355,
        },
        {
          time: '05/Nov/2021(Fri)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
          count23: 355,
          count24: 355,
          count25: 355,
          count26: 355,
        },
        {
          time: '06/Nov/2021(Sat)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
          count23: 355,
          count24: 355,
          count25: 355,
          count26: 355,
        },
        {
          time: '07/Nov/2021(Sun)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
          count23: 355,
          count24: 355,
          count25: 355,
          count26: 355,
        },
        {
          time: '08/Nov/2021(Mon)',
          count1: '112',
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
          count23: 355,
          count24: 355,
          count25: 355,
          count26: 355,
        },
        {
          time: '09/Nov/2021(Tue)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
          count23: 355,
          count24: 355,
          count25: 355,
          count26: 355,
        },
        {
          time: '10/Nov/2021(Wed)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
          count23: 355,
          count24: 355,
          count25: 355,
          count26: 355,
        },
        {
          time: '11/Nov/2021(Thu)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
          count23: 355,
          count24: 355,
          count25: 355,
          count26: 355,
        },
        {
          time: '12/Nov/2021(Fri)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
          count23: 355,
          count24: 355,
          count25: 355,
          count26: 355,
        },
        {
          time: '13/Nov/2021(Sat)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
          count23: 355,
          count24: 355,
          count25: 355,
          count26: 355,
        },
      ],
      table2FooterData: [
        {
          time: 'Cor TTL (L/R)',
          count1: '112,159',
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
          count20: 343,
          count21: 343,
          count22: 343,
          count23: 355,
          count24: 355,
          count25: 355,
          count26: 355,
        },
        {
          time: 'Cor TTL (L+R)',
          count1: '343',
          count2: '1,343',
          count3: '1,343',
          count4: '1,343',
          count5: '1,343',
          count6: '1,343',
          count7: '1,343',
          count8: '1,343',
          count9: '1,343',
          count10: '1,343',
          count11: '1,343',
          count12: '1,343',
          count13: '1,343',
        },
        {
          time: 'Block TTL (L/R)',
          count1: 'L←',
          count2: '→R',
          count3: 'L←',
          count4: '→R',
          count5: '-',
          count6: '-',
          count7: 'L←',
          count8: '→R',
          count9: '-',
          count10: '-',
          count11: 'L←',
          count12: '→R',
          count13: 'L←',
          count14: '→R',
        },
        {
          time: 'Block TTL (L/R)',
          count1: '11,862',
          count2: '12,637',
          count3: '11,261',
          count4: '9,796',
          count5: '-',
          count6: '-',
          count7: '1,242',
          count8: '4,212',
          count9: '-',
          count10: '-',
          count11: '213',
          count12: '422',
          count13: '2,012',
          count14: '2,114',
        },
        {
          time: 'Block TTL (L+R)',
          count1: '24,499',
          count2: '21,057',
          count3: '6,293',
          count4: '9,868',
          count5: '3,560',
          count6: '7,497',
          count7: '4,126',
        },
        {
          time: 'Block Ratio',
          count1: '11.4%',
          count2: '13.6%',
          count3: '4.1%',
          count4: '6.4%',
          count5: '2.3%',
          count6: '4.8%',
          count7: '2.7%',
        },
        {
          time: 'Floor TTL',
          count1: '76,900',
        },
        {
          time: 'Floor Ratio',
          count1: '49.7%',
        },
      ],
      table3Data: [
        {
          time: '01/Nov/2021(Mon)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
          count23: 355,
          count24: 355,
          count25: 355,
          count26: 355,
          count27: 355,
          count28: 355,
          count29: 355,
          count30: 355,
        },
        {
          time: '02/Nov/2021(Tue)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
          count23: 355,
          count24: 355,
          count25: 355,
          count26: 355,
          count27: 355,
          count28: 355,
          count29: 355,
          count30: 355,
        },
        {
          time: '03/Nov/2021(Wed)',
          count1: 123,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
          count23: 355,
          count24: 355,
          count25: 355,
          count26: 355,
          count27: 355,
          count28: 355,
          count29: 355,
          count30: 355,
        },
        {
          time: '04/Nov/2021(Thu)',
          count1: '111',
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
          count23: 355,
          count24: 355,
          count25: 355,
          count26: 355,
          count27: 355,
          count28: 355,
          count29: 355,
          count30: 355,
        },
        {
          time: '05/Nov/2021(Fri)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
          count23: 355,
          count24: 355,
          count25: 355,
          count26: 355,
          count27: 355,
          count28: 355,
          count29: 355,
          count30: 355,
        },
        {
          time: '06/Nov/2021(Sat)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
          count23: 355,
          count24: 355,
          count25: 355,
          count26: 355,
          count27: 355,
          count28: 355,
          count29: 355,
          count30: 355,
        },
        {
          time: '07/Nov/2021(Sun)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
          count23: 355,
          count24: 355,
          count25: 355,
          count26: 355,
          count27: 355,
          count28: 355,
          count29: 355,
          count30: 355,
        },
        {
          time: '08/Nov/2021(Mon)',
          count1: '112',
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
          count23: 355,
          count24: 355,
          count25: 355,
          count26: 355,
          count27: 355,
          count28: 355,
          count29: 355,
          count30: 355,
        },
        {
          time: '09/Nov/2021(Tue)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
          count23: 355,
          count24: 355,
          count25: 355,
          count26: 355,
          count27: 355,
          count28: 355,
          count29: 355,
          count30: 355,
        },
        {
          time: '10/Nov/2021(Wed)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
          count23: 355,
          count24: 355,
          count25: 355,
          count26: 355,
          count27: 355,
          count28: 355,
          count29: 355,
          count30: 355,
        },
        {
          time: '11/Nov/2021(Thu)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
          count23: 355,
          count24: 355,
          count25: 355,
          count26: 355,
          count27: 355,
          count28: 355,
          count29: 355,
          count30: 355,
        },
        {
          time: '12/Nov/2021(Fri)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
          count23: 355,
          count24: 355,
          count25: 355,
          count26: 355,
          count27: 355,
          count28: 355,
          count29: 355,
          count30: 355,
        },
        {
          time: '13/Nov/2021(Sat)',
          count1: 355,
          count2: 355,
          count3: 355,
          count4: 355,
          count5: 355,
          count6: 355,
          count7: 355,
          count8: 355,
          count9: 355,
          count10: 35,
          count11: 55,
          count12: 125,
          count13: 355,
          count14: 355,
          count15: 355,
          count16: 355,
          count17: 355,
          count18: 355,
          count19: 355,
          count20: 355,
          count21: 355,
          count22: 355,
          count23: 355,
          count24: 355,
          count25: 355,
          count26: 355,
          count27: 355,
          count28: 355,
          count29: 355,
          count30: 355,
        },
      ],
      table3FooterData: [
        {
          time: 'Cor TTL (L/R)',
          count1: '112,159',
          count2: 123,
          count3: 222,
          count4: 343,
          count5: 422,
          count6: 432,
          count7: 343,
          count8: 343,
          count9: 343,
          count10: 343,
          count11: 343,
          count12: 343,
          count13: 343,
          count14: 343,
          count15: 343,
          count16: 343,
          count17: 343,
          count18: 343,
          count19: 343,
          count20: 343,
          count21: 343,
          count22: 343,
          count23: 355,
          count24: 355,
          count25: 355,
          count26: 355,
          count27: 355,
          count28: 355,
          count29: 355,
          count30: 355,
        },
        {
          time: 'Cor TTL (L+R)',
          count1: '343',
          count2: '1,343',
          count3: '1,343',
          count4: '1,343',
          count5: '1,343',
          count6: '1,343',
          count7: '1,343',
          count8: '1,343',
          count9: '1,343',
          count10: '1,343',
          count11: '1,343',
          count12: '1,343',
          count13: '1,343',
          count14: '1,343',
          count15: '1,343',
        },
        {
          time: 'Block TTL (L/R)',
          count1: 'L←',
          count2: '→R',
          count3: 'L←',
          count4: '→R',
          count5: '-',
          count6: '-',
          count7: 'L←',
          count8: '→R',
          count9: 'U↑',
          count10: 'D↓',
          count11: '-',
          count12: '-',
          count13: 'L←',
          count14: '→R',
          count15: 'U↑',
          count16: 'D↓',
        },
        {
          time: 'Block TTL (L/R)',
          count1: '11,862',
          count2: '12,637',
          count3: '11,261',
          count4: '9,796',
          count5: '-',
          count6: '-',
          count7: '24,232',
          count8: '24,232',
          count9: '24,232',
          count10: '124,232',
          count11: '-',
          count12: '-',
          count13: '2,012',
          count14: '2,114',
          count15: '2,012',
          count16: '2,114',
        },
        {
          time: 'Block TTL (L+R)',
          count1: '24,499',
          count2: '21,057',
          count3: '6,293',
          count4: '9,868',
          count5: '3,560',
          count6: '7,497',
          count7: '4,126',
          count8: '4,026',
        },
        {
          time: 'Block Ratio',
          count1: '11.4%',
          count2: '13.6%',
          count3: '4.1%',
          count4: '6.4%',
          count5: '2.3%',
          count6: '4.8%',
          count7: '2.7%',
          count8: '2.7%',
        },
        {
          time: 'Floor TTL',
          count1: '76,900',
        },
        {
          time: 'Floor Ratio',
          count1: '49.7%',
        },
      ],
      holidayData: [
        '11/Nov/2021(Thu)',
      ],
      arrowData: [
        {
          id: '4454',
          rotate: '0deg',
          positionX: '26.6%',
          positionY: '7.15%',
          color: '#E53935',
        },
        {
          id: '4455',
          rotate: '180deg',
          positionX: '30%',
          positionY: '7.15%',
          color: '#4CAF50',
        },
      ],
      blockData: [
        {
          id: '4454',
          positionX: '18.3%',
          positionY: '6.5%',
          title: 'Block A',
          blockInfo: [
            {
              direction: 'L',
              count: '1,212,987',
            },
            {
              direction: 'R',
              count: '2,212,987',
            },
          ],
          titleBgColor: '#ff99fd',
          borderColor: '#ffccfe',
        },
        {
          id: '4456',
          positionX: '69%',
          positionY: '20.5%',
          title: 'Block B',
          blockInfo: [
            {
              direction: 'L',
              count: '2,987',
            },
            {
              direction: 'R',
              count: '2,987',
            },
          ],
        },
      ],
      blockChartData: {
        left: {
          name: 'L←',
          data: [
            {
              value: 1115,
              label: 'Block FC',
            },
            {
              value: 1115,
              label: 'Block U',
            },
            {
              value: 1115,
              label: 'Block T',
            },
            {
              value: 1115,
              label: 'Block S',
            },
            {
              value: 1245,
              label: 'Block R',
            },
            {
              value: 1235,
              label: 'Block Q',
            },
            {
              value: 2345,
              label: 'Block P',
            },
            {
              value: 2145,
              label: 'Block O',
            },
            {
              value: 2215,
              label: 'Block N',
            },
            {
              value: 1115,
              label: 'Block M',
            },
            {
              value: 425,
              label: 'Block L',
            },
            {
              value: 513,
              label: 'Block K',
            },
            {
              value: 1115,
              label: 'Block J',
            },
            {
              value: 115,
              label: 'Block I',
            },
            {
              value: 1115,
              label: 'Block H',
            },
            {
              value: 1112,
              label: 'Block G',
            },
            {
              value: 1110,
              label: 'Block F',
            },
            {
              value: 1217,
              label: 'Block E',
            },
            {
              value: 1312,
              label: 'Block D',
            },
            {
              value: 1410,
              label: 'Block C',
            },
            {
              value: 2118,
              label: 'Block B',
            },
            {
              value: 1234,
              label: 'Block A',
            },
          ],
        },
        right: {
          name: '→R',
          data: [
            {
              value: 1325,
              label: 'Block FC',
            },
            {
              value: 1315,
              label: 'Block U',
            },
            {
              value: 1225,
              label: 'Block T',
            },
            {
              value: 1135,
              label: 'Block S',
            },
            {
              value: 1145,
              label: 'Block R',
            },
            {
              value: 544,
              label: 'Block Q',
            },
            {
              value: 1051,
              label: 'Block P',
            },
            {
              value: 2053,
              label: 'Block O',
            },
            {
              value: 2151,
              label: 'Block N',
            },
            {
              value: 2150,
              label: 'Block M',
            },
            {
              value: 1145,
              label: 'Block L',
            },
            {
              value: 1143,
              label: 'Block K',
            },
            {
              value: 1042,
              label: 'Block J',
            },
            {
              value: 1045,
              label: 'Block I',
            },
            {
              value: 2125,
              label: 'Block H',
            },
            {
              value: 1119,
              label: 'Block G',
            },
            {
              value: 1123,
              label: 'Block F',
            },
            {
              value: 1143,
              label: 'Block E',
            },
            {
              value: 1134,
              label: 'Block D',
            },
            {
              value: 1153,
              label: 'Block C',
            },
            {
              value: 1112,
              label: 'Block B',
            },
            {
              value: 1134,
              label: 'Block A',
            },
          ],
        },
      },
    },
  ],
}

// ------------------------------------------------
// GET: Return Single data
// ------------------------------------------------
mock.onGet(/\/apps\/corridor\/dates\/\d+/).reply(config => {
  // Get event id from URL
  let id = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  id = Number(id)

  const index = data.dates.findIndex(e => e.id === id)
  const dat = data.dates[index]
  Object.assign(dat, {
    taskDone: 1230,
    projectDone: 568,
    taxId: 'Tax-8894',
    language: 'English',
  })

  if (dat) return [200, dat]

  return [404]
})
