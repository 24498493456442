const { default: mock } = require('@/@fake-db/mock')

/* eslint-disable global-require */
// data
const data = {
  dates: [
    {
      id: 1,
      rowData: [
        {
          date: '01 / Jan / 2022',
          day: 'Sat',
          fixedHoliday: 'New Year\'s Day',
          specialHoliday: 'Toyota',
          memo1: 'Celica',
          memo2: 'test',
          memo3: 'test',
        },
        {
          date: '02 / Jan / 2022',
          day: 'Sun',
          fixedHoliday: '',
          specialHoliday: '',
          memo1: 'Celica',
          memo2: '',
          memo3: '',
        },
        {
          date: '03 / Jan / 2022',
          day: 'Mon',
          fixedHoliday: 'Substitute Holiday',
          specialHoliday: '',
          memo1: 'Celica',
          memo2: '',
          memo3: '',
        },
        {
          date: '04 / Jan / 2022',
          day: 'Tue',
          fixedHoliday: '',
          specialHoliday: '',
          memo1: 'Celica',
          memo2: '',
          memo3: '',
        },
        {
          date: '05 / Jan / 2022',
          day: 'Wed',
          fixedHoliday: '',
          specialHoliday: '',
          memo1: 'Celica',
          memo2: '',
          memo3: '',
        },
        {
          date: '06 / Jan / 2022',
          day: 'Thu',
          fixedHoliday: '',
          specialHoliday: '',
          memo1: 'Celica',
          memo2: '',
          memo3: '',
        },
        {
          date: '07 / Jan / 2022',
          day: 'Fri',
          fixedHoliday: '',
          specialHoliday: '',
          memo1: 'Celica',
          memo2: '',
          memo3: '',
        },
        {
          date: '08 / Jan / 2022',
          day: 'Sat',
          fixedHoliday: '',
          specialHoliday: 'Toyota',
          memo1: 'Celica',
          memo2: 'test',
          memo3: 'test',
        },
        {
          date: '09 / Jan / 2022',
          day: 'Sun',
          fixedHoliday: '',
          specialHoliday: '',
          memo1: 'Celica',
          memo2: '',
          memo3: '',
        },
        {
          date: '10 / Jan / 2022',
          day: 'Mon',
          fixedHoliday: '',
          specialHoliday: '',
          memo1: 'Celica',
          memo2: '',
          memo3: '',
        },
        {
          date: '11 / Jan / 2022',
          day: 'Tue',
          fixedHoliday: '',
          specialHoliday: '',
          memo1: 'Celica',
          memo2: '',
          memo3: '',
        },
        {
          date: '12 / Jan / 2022',
          day: 'Wed',
          fixedHoliday: '',
          specialHoliday: '',
          memo1: 'Celica',
          memo2: '',
          memo3: '',
        },
        {
          date: '13 / Jan / 2022',
          day: 'Thu',
          fixedHoliday: '',
          specialHoliday: '',
          memo1: 'Celica',
          memo2: '',
          memo3: '',
        },
        {
          date: '14 / Jan / 2022',
          day: 'Fri',
          fixedHoliday: '',
          specialHoliday: '',
          memo1: 'Celica',
          memo2: '',
          memo3: '',
        },
        {
          date: '15 / Jan / 2022',
          day: 'Sat',
          fixedHoliday: '',
          specialHoliday: 'Toyota',
          memo1: 'Celica',
          memo2: 'test',
          memo3: 'test',
        },
        {
          date: '16 / Jan / 2022',
          day: 'Sun',
          fixedHoliday: '',
          specialHoliday: '',
          memo1: 'Celica',
          memo2: '',
          memo3: '',
        },
        {
          date: '17 / Jan / 2022',
          day: 'Mon',
          fixedHoliday: '',
          specialHoliday: '',
          memo1: 'Celica',
          memo2: '',
          memo3: '',
        },
        {
          date: '18 / Jan / 2022',
          day: 'Tue',
          fixedHoliday: '',
          specialHoliday: '',
          memo1: 'Celica',
          memo2: '',
          memo3: '',
        },
        {
          date: '19 / Jan / 2022',
          day: 'Wed',
          fixedHoliday: '',
          specialHoliday: '',
          memo1: 'Celica',
          memo2: '',
          memo3: '',
        },
        {
          date: '20 / Jan / 2022',
          day: 'Thu',
          fixedHoliday: '',
          specialHoliday: '',
          memo1: 'Celica',
          memo2: '',
          memo3: '',
        },
        {
          date: '21 / Jan / 2022',
          day: 'Fri',
          fixedHoliday: '',
          specialHoliday: '',
          memo1: 'Celica',
          memo2: '',
          memo3: '',
        },
        {
          date: '22 / Jan / 2022',
          day: 'Sat',
          fixedHoliday: '',
          specialHoliday: 'Toyota',
          memo1: 'Celica',
          memo2: 'test',
          memo3: 'test',
        },
        {
          date: '23 / Jan / 2022',
          day: 'Sun',
          fixedHoliday: '',
          specialHoliday: '',
          memo1: 'Celica',
          memo2: '',
          memo3: '',
        },
        {
          date: '24 / Jan / 2022',
          day: 'Mon',
          fixedHoliday: '',
          specialHoliday: '',
          memo1: 'Celica',
          memo2: '',
          memo3: '',
        },
        {
          date: '25 / Jan / 2022',
          day: 'Tue',
          fixedHoliday: '',
          specialHoliday: '',
          memo1: 'Celica',
          memo2: '',
          memo3: '',
        },
        {
          date: '26 / Jan / 2022',
          day: 'Wed',
          fixedHoliday: '',
          specialHoliday: '',
          memo1: 'Celica',
          memo2: '',
          memo3: '',
        },
        {
          date: '27 / Jan / 2022',
          day: 'Thu',
          fixedHoliday: '',
          specialHoliday: '',
          memo1: 'Celica',
          memo2: '',
          memo3: '',
        },
        {
          date: '28 / Jan / 2022',
          day: 'Fri',
          fixedHoliday: '',
          specialHoliday: '',
          memo1: 'Celica',
          memo2: '',
          memo3: '',
        },
        {
          date: '29 / Jan / 2022',
          day: 'Sat',
          fixedHoliday: '',
          specialHoliday: '',
          memo1: 'Celica',
          memo2: '',
          memo3: '',
        },
        {
          date: '30 / Jan / 2022',
          day: 'Sun',
          fixedHoliday: '',
          specialHoliday: '',
          memo1: 'Celica',
          memo2: '',
          memo3: '',
        },
        {
          date: '31 / Jan / 2022',
          day: 'Mon',
          fixedHoliday: '',
          specialHoliday: '',
          memo1: 'Celica',
          memo2: '',
          memo3: '',
        },
      ],
    },
  ],
}

// ------------------------------------------------
// GET: Return Single data
// ------------------------------------------------
mock.onGet(/\/apps\/memobox\/dates\/\d+/).reply(config => {
  // Get event id from URL
  let id = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  id = Number(id)

  const index = data.dates.findIndex(e => e.id === id)
  const dat = data.dates[index]
  Object.assign(dat, {
    taskDone: 1230,
    projectDone: 568,
    taxId: 'Tax-8894',
    language: 'English',
  })

  if (dat) return [200, dat]

  return [404]
})
