<template>
  <v-menu
    open-on-hover
    :close-on-content-click="true"
    bottom
    transition="slide-y-transition"
    :offset-y="offset"
  >
    <template
      v-if="userData.role != 'admin'"
      v-slot:activator="{ on, attrs }"
    >
      <v-btn
        color="info"
        dark
        v-bind="attrs"
        style="text-transform: none"
        v-on="on"
      >
        {{ currentSC }}
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="(item, index) in sitelist"
        :key="index"
        @click="changeSc(item.menu, item.siteName, item.siteId)"
      >
        <v-list-item-title>{{ item.siteName }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { constant } from '@core/utils/constant'
import crypto from 'crypto-js'
import { updateSiteId, useRouter } from '@core/utils'
import { getCurrentInstance, ref } from '@vue/composition-api'
import { initialAbility } from '@/plugins/acl/config'

// function post(path, params, method = 'post') {
//   const form = document.createElement('form')
//   form.method = method
//   form.action = path

//   Object.keys(params).forEach(key => {
//     if (params.hasOwnProperty.call(params, key)) {
//       const hiddenField = document.createElement('input')
//       hiddenField.type = 'hidden'
//       hiddenField.name = key
//       hiddenField.value = params[key]

//       form.appendChild(hiddenField)
//     }
//   })

//   document.body.appendChild(form)
//   form.submit()
// }

export default {
  setup() {
    // 拠点情報、ユーザ情報取得
    const ecryptedData = sessionStorage.getItem('siteList')

    // 復号する
    const decrypted = crypto.AES.decrypt(ecryptedData, constant.secret)
    const siteList = JSON.parse(decrypted.toString(crypto.enc.Utf8))

    const vm = getCurrentInstance().proxy
    const { router } = useRouter()
    const currentSC = ref()

    // 拠点変更
    const changeSc = (menu, siteName, siteId) => {
      updateSiteId(siteId)
      const userAbility = JSON.parse(menu)
      vm.$ability.update(initialAbility)
      vm.$ability.update(userAbility)

      currentSC.value = siteName

      // 暗号化する
      const abilityEcrypted = crypto.AES.encrypt(JSON.stringify(userAbility), constant.secret)
      sessionStorage.setItem('userAbility', abilityEcrypted.toString())

      console.log(router.currentRoute.path)

      if (router.currentRoute.path === '/apps/entrance') {
        router.go(0)
      } else {
        router.push('/')
      }
    }

    const ecrypted = sessionStorage.getItem('data')

    // 復号する
    const decrypt = crypto.AES.decrypt(ecrypted, constant.secret)
    const userData = JSON.parse(decrypt.toString(crypto.enc.Utf8))

    const scList = []
    siteList.forEach(element => {
      const json = {
        siteName: element.name,
        menu: element.menu,
        siteId: element.id,
      }

      scList.push(json)

      if (userData.siteId === element.id) {
        currentSC.value = element.name
      }
    })

    return {
      userData,
      currentSC,
      changeSc,
      sitelist: scList,
      offset: true,
    }
  },
}
</script>
