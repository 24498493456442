// eslint-disable-next-line object-curly-newline
import { getCurrentInstance, reactive, toRefs, watch } from '@vue/composition-api'
import { constant } from '@core/utils/constant'
import crypto from 'crypto-js'

export const isObject = obj => typeof obj === 'object' && obj !== null

export const isToday = date => {
  const today = new Date()

  return (
  /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

export const dateInPast = (firstDate, secondDate) => {
  if (firstDate.setHours(0, 0, 0, 0) <= secondDate.setHours(0, 0, 0, 0)) {
    return true
  }

  return false
}

export const getVuetify = () => {
  const ins = getCurrentInstance()?.proxy

  return ins && ins.$vuetify ? ins.$vuetify : null
}

// Thanks: https://medium.com/better-programming/reactive-vue-routes-with-the-composition-api-18c1abd878d1
export const useRouter = () => {
  const vm = getCurrentInstance().proxy

  const state = reactive({
    route: vm.$route,
  })

  watch(
    () => vm.$route,
    r => {
      state.route = r
    },
  )

  return { ...toRefs(state), router: vm.$router }
}

export const isEmpty = value => {
  if (value === null || value === undefined || value === '') {
    return true
  }

  if (Array.isArray(value) && value.length === 0) {
    return true
  }

  return false
}

// ——— Get Initial Text from name ——————— //

export const getInitialName = fullName =>
// eslint-disable-next-line implicit-arrow-linebreak
  fullName
    .split(' ')
    .map(n => n[0])
    .join('')

// ——— Add Alpha To color ——————— //

export const addAlpha = (color, opacity) => {
  const opacityLocal = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255)

  return color + opacityLocal.toString(16).toUpperCase()
}

// ——— Perfect Scrollbar Scroll to bottom ——————— //

export const psScrollToBottom = psRef => () => {
  const scrollEl = psRef.value.$el || psRef.value
  scrollEl.scrollTop = scrollEl.scrollHeight
}

// ——— Perfect Scrollbar Scroll to bottom ——————— //

export const psScrollToTop = psRef => () => {
  const scrollEl = psRef.value.$el || psRef.value
  scrollEl.scrollTop = 0
}

// ————————————————————————————————————
//* ——— Color Manipulations
// ————————————————————————————————————

// Thanks: https://stackoverflow.com/a/5624139/10796681
export const rgbToHex = (r, g, b) => {
  const componentToHex = c => {
    const hex = c.toString(16)

    return hex.length === 1 ? `0${hex}` : hex
  }

  return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`
}

// Thanks: https://stackoverflow.com/a/5624139/10796681
export const hexToRgb = hex => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  // eslint-disable-next-line no-param-reassign
  hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b)

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

  /* eslint-disable indent */
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null
  /* eslint-enable */
}

export const getSessionData = key => {
  const ecryptedData = sessionStorage.getItem(key)

  // 復号する
  const decrypted = crypto.AES.decrypt(ecryptedData, constant.secret)
  const data = JSON.parse(decrypted.toString(crypto.enc.Utf8))

  return data
}

export const updateSiteId = siteId => {
  const ecryptedData = sessionStorage.getItem('data')

  // 復号する
  const decrypted = crypto.AES.decrypt(ecryptedData, constant.secret)
  const data = JSON.parse(decrypted.toString(crypto.enc.Utf8))
  data.siteId = siteId
  const ecrypted = crypto.AES.encrypt(JSON.stringify(data), constant.secret)

  sessionStorage.setItem('data', ecrypted.toString())
}

export const date2String = date => {
  const stringContext = `${date.getFullYear()}
  -${(date.getMonth() + 1).toString().padStart(2, '0')}
  -${date.getDate().toString().padStart(2, '0')}`.replace(/\s+/g, '')

  return stringContext
}

export const date2yyyymmddhhmmss = date => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

  const stringContext = `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}_${date.getDate().toString().padStart(2, '0')}${months[date.getMonth()]}${date.getFullYear()}`.replace(/\s+/g, '')

  return stringContext
}

export const dateFormattedddmmmyyydd = dateS => {
  const date = new Date(dateS)
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

  // DD/MMM/YYYY(ddd)
  const stringContext = `${date.getDate().toString().padStart(2, '0')}/${months[date.getMonth()]}/${date.getFullYear()}(${days[date.getDay()]})`.replace(/\s+/g, '')

  return stringContext
}

export const dateFormattedddmmm = dateS => {
  const date = new Date(dateS)
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

  // DD/MMM(ddd)
  const stringContext = `${date.getDate().toString().padStart(2, '0')}/${months[date.getMonth()]}(${days[date.getDay()]})`.replace(/\s+/g, '')

  return stringContext
}

export const dateFormattedJP = date => {
  const days = ['日', '月', '火', '水', '木', '金', '土']

  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  const stringContext = `${year}年${month}月${day}日（${days[date.getDay()]}）`.replace(/\s+/g, '')

  return stringContext
}
