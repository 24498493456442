<template>
  <div>
    <v-row
      cols="12"
      sm="6"
      md="4"
    >
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="computedDateFormatted"
            :prepend-icon="icons.mdiCalendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          :weekday-format="getDay"
          v-model="date"
          locale="en"
          color="primary"
          @change="handleChange"
          @input="menu = false"
        ></v-date-picker>
      </v-menu>&nbsp;&nbsp;
      <v-btn
        color="info"
        style="text-transform: none"
        @click="search"
      >
        <v-icon>
          {{ icons.mdiMagnify }}
        </v-icon>
        <div>Search</div>
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiCalendar } from '@mdi/js'
import { isEmpty, dateFormattedddmmmyyydd } from '@core/utils'

const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

export default {
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date)
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return null

      return dateFormattedddmmmyyydd(date)
    },
    getDay(date) {
      const i = new Date(date).getDay(date)

      return daysOfWeek[i]
    },
  },
  setup(props, context) {
    let date = sessionStorage.getItem('searchDate') // new Date().toISOString().substr(0, 10)
    if (isEmpty(date)) {
      date = new Date().toISOString().substr(0, 10)
    }

    const handleChange = date2 => {
      sessionStorage.setItem('searchDate', date2)
      date = date2
    }

    const search = () => {
      context.emit('dataSearch')
    }

    const menu = ref(false)
    const modal = ref(false)
    const menuref = ref(null)

    return {
      isEmpty,
      handleChange,
      search,
      date,
      menu,
      modal,
      menuref,

      // icons
      icons: {
        mdiCalendar,
      },
    }
  },
}
</script>

<style>
</style>
