const { default: mock } = require('@/@fake-db/mock')

/* eslint-disable global-require */
// data
const data = {
  dates: [
    {
      id: 1,
      searchType: 1,
      text: '',
      seriesData: [
        213, 2441, 3735, 3800, 3647, 3077, 3184, 3922, 3962, 3948, 4146, 5371, 5482, 1120, 136,
      ],
      legendData: [
        '08:00',
        '09:00',
        '10:00',
        '11:00',
        '12:00',
        '13:00',
        '14:00',
        '15:00',
        '16:00',
        '17:00',
        '18:00',
        '19:00',
        '20:00',
        '21:00',
        '22:00',
      ],
      stayData: [
        {
          time: '08:00',
          count: 159,
        },
        {
          time: '09:00',
          count: 159,
        },
        {
          time: '10:00',
          count: 3735,
        },
        {
          time: '11:00',
          count: 3800,
        },
        {
          time: '12:00',
          count: 3647,
        },
        {
          time: '13:00',
          count: 3077,
        },
        {
          time: '14:00',
          count: 3184,
        },
        {
          time: '15:00',
          count: 3922,
        },
        {
          time: '16:00',
          count: 3962,
        },
        {
          time: '17:00',
          count: 3948,
        },
        {
          time: '18:00',
          count: 4146,
        },
        {
          time: '19:00',
          count: 5371,
        },
        {
          time: '20:00',
          count: 5482,
        },
        {
          time: '21:00',
          count: 1120,
        },
        {
          time: '22:00',
          count: 136,
        },
      ],
    },
    {
      id: 2,
      searchType: 2,
      stayData: [
        {
          time: '08:00',
          sunCount: 159,
          monCount: 123,
          tueCount: 222,
          wedCount: 343,
          thuCount: 422,
          friCount: 432,
          satCount: 343,
        },
        {
          time: '09:00',
          sunCount: 159,
          monCount: 123,
          tueCount: 222,
          wedCount: 343,
          thuCount: 422,
          friCount: 432,
          satCount: 343,
        },
        {
          time: '10:00',
          sunCount: 159,
          monCount: 123,
          tueCount: 222,
          wedCount: 343,
          thuCount: 422,
          friCount: 432,
          satCount: 343,
        },
        {
          time: '11:00',
          sunCount: 159,
          monCount: 123,
          tueCount: 222,
          wedCount: 343,
          thuCount: 422,
          friCount: 432,
          satCount: 343,
        },
        {
          time: '12:00',
          sunCount: 159,
          monCount: 123,
          tueCount: 222,
          wedCount: 343,
          thuCount: 422,
          friCount: 432,
          satCount: 343,
        },
        {
          time: '13:00',
          sunCount: 159,
          monCount: 123,
          tueCount: 222,
          wedCount: 343,
          thuCount: 422,
          friCount: 432,
          satCount: 343,
        },
        {
          time: '14:00',
          sunCount: 159,
          monCount: 123,
          tueCount: 222,
          wedCount: 343,
          thuCount: 422,
          friCount: 432,
          satCount: 343,
        },
        {
          time: '15:00',
          sunCount: 159,
          monCount: 123,
          tueCount: 222,
          wedCount: 343,
          thuCount: 422,
          friCount: 432,
          satCount: 343,
        },
        {
          time: '16:00',
          sunCount: 159,
          monCount: 123,
          tueCount: 222,
          wedCount: 343,
          thuCount: 422,
          friCount: 432,
          satCount: 343,
        },
        {
          time: '17:00',
          sunCount: 159,
          monCount: 123,
          tueCount: 222,
          wedCount: 343,
          thuCount: 422,
          friCount: 432,
          satCount: 343,
        },
        {
          time: '18:00',
          sunCount: 159,
          monCount: 123,
          tueCount: 222,
          wedCount: 343,
          thuCount: 422,
          friCount: 432,
          satCount: 343,
        },
        {
          time: '19:00',
          sunCount: 159,
          monCount: 123,
          tueCount: 222,
          wedCount: 343,
          thuCount: 422,
          friCount: 432,
          satCount: 343,
        },
        {
          time: '20:00',
          sunCount: 159,
          monCount: 123,
          tueCount: 222,
          wedCount: 343,
          thuCount: 422,
          friCount: 432,
          satCount: 343,
        },
        {
          time: '21:00',
          sunCount: 159,
          monCount: 123,
          tueCount: 222,
          wedCount: 343,
          thuCount: 422,
          friCount: 432,
          satCount: 343,
        },
        {
          time: '22:00',
          sunCount: 159,
          monCount: 123,
          tueCount: 222,
          wedCount: 343,
          thuCount: 422,
          friCount: 432,
          satCount: 343,
        },
      ],
    },
  ],
}

// ------------------------------------------------
// GET: Return Single data
// ------------------------------------------------
mock.onGet(/\/apps\/stay\/dates\/\d+/).reply(config => {
  // Get event id from URL
  let id = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  id = Number(id)

  const index = data.dates.findIndex(e => e.id === id)
  const dat = data.dates[index]
  Object.assign(dat, {
    taskDone: 1230,
    projectDone: 568,
    taxId: 'Tax-8894',
    language: 'English',
  })

  if (dat) return [200, dat]

  return [404]
})
