/* eslint-disable import/extensions */
import './data/apps/calendar'
import './data/apps/chat'
import './data/apps/email'
import './data/apps/invoice'
import './data/apps/user'
import './data/table/datatable'
import './data/apps/entrance'
import './data/apps/corridor'
import './data/apps/parking'
import './data/apps/stay'
import './data/apps/comparison'
import './data/apps/combined'
import './data/apps/memobox'
import './data/apps/toilet'
import './data/apps/entranceSearch1'
import './data/apps/entranceSearch2'
import './data/apps/entranceSearch3'
import './data/apps/entranceSearch4'
import './data/apps/corridorSearch1'
import './jwt/index'
import mock from './mock'

/* eslint-enable import/extensions */

mock.onAny().passThrough() // forwards the matched request over network
