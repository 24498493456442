const { default: mock } = require('@/@fake-db/mock')

/* eslint-disable global-require */
// data
const data = {
  dates: [
    {
      id: 2,
      text: '2020 - 2023',
      seriesData: [
        {
          name: '1/Jan New Year Day',
          data: [
            120, 1132, 1011, 134,
          ],
        },
        {
          name: '30/Apr Reunification Day',
          data: [
            220, 182, 1911, 234,
          ],
        },
        {
          name: '31/Dec New Year Eve',
          data: [
            220, 182, 1911, 234,
          ],
        },
      ],
      xAxisData: [
        '2020', '2021', '2022', '2023',
      ],
      legendData: [
        '1/Jan New Year Day', '30/Apr Reunification Day', '31/Dec New Year Eve',
      ],
    },
  ],
}

// ------------------------------------------------
// GET: Return Single data
// ------------------------------------------------
mock.onGet(/\/apps\/entranceSearch3\/dates\/\d+/).reply(config => {
  // Get event id from URL
  let id = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  id = Number(id)

  const index = data.dates.findIndex(e => e.id === id)
  const dat = data.dates[index]
  Object.assign(dat, {
    taskDone: 1230,
    projectDone: 568,
    taxId: 'Tax-8894',
    language: 'English',
  })

  if (dat) return [200, dat]

  return [404]
})
